import { useRef, useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import * as endpoints from '../../../utils/endpoints';
import { LockClosedIcon } from '@heroicons/react/solid';
import SpinnerIcon from '../../components/SpinnerIcon';
import { ExclamationIcon } from '@heroicons/react/outline';
import FloatingBanner from '../../components/FloatingBanner';
import LazyLoadImage from '../../components/LazyLoadImage';
import loginPageImage from '../../../assets/SSR_home_brighter.jpg';

export default function Login() {
  const { auth, setAuth } = useAuth();
  const [isFetching, setIsFetching] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const userRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    // If the user is already logged in, redirect to the home page
    if (auth?.email) {
      navigate('/');
      return;
    }
    userRef.current.focus();

    return () => {
      setIsFetching(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginRequest = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
      }),
    };
    try {
      setIsFetching(true);
      const response = await fetch(endpoints.API + endpoints.LOGIN, loginRequest);
      if (!response.ok) {
        setErrMsg('Login Failed');
        setShowBanner(true);
        setTimeout(() => {
          setErrMsg('');
          setShowBanner(false);
        }, 1500);
      } else {
        const res = await response.json();
        setAuth({
          email,
          id: res.id,
          roles: res.roles,
          accessToken: res.token,
        });
        localStorage.setItem('accessToken', res.token);
        setEmail('');
        setPassword('');
        navigate(from, { replace: true });
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
    }
    setIsFetching(false);
  };

  return (
    <>
      <FloatingBanner
        show={showBanner}
        setShow={setShowBanner}
        icon={<ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />}
        text={errMsg}
        primaryColor="bg-red-600"
        secondaryColor="bg-red-800"
        hoverColor="bg-red-500"
      />
      <div className="min-h-full h-[calc(100vh-65px)] flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 mb-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        id="email-address"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        name="email"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Email address"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        type="password"
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        name="password"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Password"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={isFetching}
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        {isFetching ? (
                          <SpinnerIcon />
                        ) : (
                          <LockClosedIcon
                            className="h-5 w-5 text-gray-500 group-hover:text-gray-400"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <LazyLoadImage
            className="absolute inset-0 h-full w-full object-cover"
            src={loginPageImage}
            alt="cover_img"
          />
        </div>
      </div>
    </>
  );
}
