import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useFetchCall from '../../api/useFetchCall';
import * as endpoints from '../../utils/endpoints';
import * as constantsRouting from '../../utils/routing/constants';

import UserSummaryForm from './UserSummaryForm';
import Loading from '../Loading';
import useAuth from '../../hooks/useAuth';
import AdminMissionForm from '../admin/AdminMissionForm';
import Breadcrumbs from '../components/Breadcrumbs';

const UserForm = () => {
  const { auth } = useAuth();
  const { formId } = useParams();
  const accessToken = localStorage.getItem('accessToken');

  const [form, setForm] = useState({});

  const getUserFormsRequest = {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  };

  const [data, loading] = useFetchCall(endpoints.USER_FORM(formId), getUserFormsRequest);

  useEffect(() => {
    if (data?.data) {
      setForm(data.data);
    }
  }, [data]);

  return !loading ? (
    <>
      <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pl-4 pt-8 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
        <Breadcrumbs
          pages={[
            { name: 'Submitted Form', href: constantsRouting.ROUTE_HOME },
            { name: `${form?.name}`, href: '#' },
          ]}
        />
      </div>
      {auth.roles.includes(constantsRouting.ROLES.Admin) && (
        <AdminMissionForm debug={false} form={form} />
      )}
      {auth.roles.includes(constantsRouting.ROLES.User) && (
        <UserSummaryForm missionForm={form.data} />
      )}
    </>
  ) : (
    <Loading />
  );
};

export default UserForm;
