import React from 'react';
import { NavLink } from 'react-router-dom';

import { navBarRoutes, ROUTE_HOME } from '../../../utils/routing/constants';
import logo_no_text from '../../../assets/logo_no_text.svg';
import LazyLoadImage from '../../components/LazyLoadImage';

const buildNavbarNavLinks = (route) =>
  React.Children.toArray(
    navBarRoutes.get(route).map(({ path, label }) => (
      <NavLink to={path} className={'text-black text-lg mx-6 hover:text-indigo-700'}>
        {label}
      </NavLink>
    ))
  );

const buildNavbarRoutesByRole = (auth) =>
  React.Children.toArray(
    [...navBarRoutes].map(([key, value]) => (
      <div>{auth?.email && auth?.roles.includes(key) && buildNavbarNavLinks(key)}</div>
    ))
  );

const LeftSide = ({ auth }) => {
  return (
    <div className="flex-1 flex items-center justify-center md:justify-start">
      <div className="flex-shrink-0 flex items-center">
        <NavLink to={ROUTE_HOME}>
          <LazyLoadImage
            className="mx-auto h-14 w-auto"
            src={logo_no_text}
            alt="logo_no_text_navbar"
          />
        </NavLink>
      </div>
      <div className="hidden md:block md:ml-6">{buildNavbarRoutesByRole(auth)}</div>
    </div>
  );
};

export default LeftSide;
