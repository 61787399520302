import { useEffect, useState } from 'react';
import { useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import * as endpoints from '../utils/endpoints';
import handleLogout from '../utils/routing/handleLogout';
import { ROUTE_LOGIN, ROUTE_UNAUTHORIZED } from '../utils/routing/constants';
import Loading from './Loading';

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    // Fetch status role from token if available

    const parseJwt = (token) => {
      try {
        return JSON.parse(window.atob(token.split('.')[1]));
      } catch (e) {
        return null;
      }
    };

    const profileRequest = {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
    };

    const getProfile = async (userId) => {
      setLoading(true);
      try {
        const response = await fetch(
          `${endpoints.API}${endpoints.PROFILE(userId)}`,
          profileRequest
        );
        if (!response.ok) {
          const js = await response.json();
          console.log(js);
        } else {
          const { email, id, roles } = await response.json();
          setAuth({
            email,
            id,
            roles,
            accessToken,
          });
          location?.pathname ? navigate(location.pathname) : navigate('/');
        }
      } catch (err) {}
      setLoading(false);
    };
    if (accessToken) {
      const decodedJwt = parseJwt(accessToken);
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.removeItem('accessToken');
        handleLogout(setAuth, navigate);
        setLoading(false);
        return;
      }
      getProfile(decodedJwt.id);
      return;
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ? (
    auth?.roles?.find((role) => allowedRoles?.includes(role)) ? (
      <Outlet />
    ) : auth?.email ? (
      <Navigate to={ROUTE_UNAUTHORIZED} state={{ from: location }} replace />
    ) : (
      <Navigate to={ROUTE_LOGIN} state={{ from: location }} replace />
    )
  ) : (
    <Loading />
  );
};

export default RequireAuth;
