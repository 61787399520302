import config from '../config.js';

export const API = config.API_URL;
export const APP_VERSION = config.APP_VERSION;
export const LOGIN = '/users/login';
export const REGISTER = '/users/register';
export const CHANGE_PASSWORD = '/users/changepassword';
export const PROFILE = (userId) => `/users/profile/${userId}`;
export const USERS = '/users';
export const FORMS = '/forms';
export const SUBMITTED_FORMS = '/forms/submitted';
export const SUBMIT_FORM = '/forms/create';
export const SAVE_FORM = '/forms/save';
export const USER_FORMS = (userId) => `/forms/users/${userId}`;
export const USER_FORM = (userId) => `/forms/${userId}`;
export const USER_SAVED_FORMS = `/forms/form/save`;
export const USER_SAVED_FORM = (formId) => `/forms/form/save/${formId}`;
export const EXPORT_SAVED_FORM = `/forms/form/save/export`;

export const RATE_FORM = '/forms/submit/export';
export const CHANGES_REQUESTED_FORM = '/forms/request/changes';
export const DUPLICATE_REQUESTED_FORM = '/forms/request/duplicate';
export const DUPLICATE_SEND_FORM = '/forms/send/duplicate';
export const USER_RATED_FORMS = (userId) => `/forms/rate/user/${userId}`;
export const USER_RATED_FORM = (formId) => `/forms/rate/${formId}`;
