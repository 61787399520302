import displayModule from './DisplayModule';
import Tabs from './Tabs';

const MissionForm = ({
  currentTab,
  setCurrentTab,
  tabsTitles,
  missionForm,
  setMissionForm,
  displayType,
}) => {
  const SwitchTabs = () => {
    const getTabModule = (target) => {
      return displayModule({
        module: missionForm[target],
        setChangesModule: (updatedAnswers) =>
          setMissionForm({ ...missionForm, [target]: updatedAnswers }),
        displayType,
      });
    };
    switch (currentTab) {
      case 'Mission Index':
        return getTabModule('missionIndex');
      case 'Collision Avoidance Capabilities':
        return getTabModule('collisionAvoidance');
      case 'Data Sharing':
        return getTabModule('dataSharing');
      case 'Detection Identification Tracking':
        return getTabModule('detectionIdentificationTracking');
      case 'Application of Design and Operation Standards':
        return getTabModule('applicationOfDesignAndOperation');
      case 'External Services':
        return getTabModule('externalServices');
      default:
        break;
    }
  };

  return (
    <>
      <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} tabs={tabsTitles} />
      <div className="py-4">{SwitchTabs()}</div>
    </>
  );
};

export default MissionForm;
