import React, { useEffect, useState } from 'react';
import {
  GlobeAltIcon,
  CloudUploadIcon,
  RefreshIcon,
  DocumentDuplicateIcon,
  ArrowCircleDownIcon,
  SaveIcon,
} from '@heroicons/react/outline';

import MissionForm from '../user/components/MissionForm';

import * as constantsModule from '../../utils/modules/constants';
import * as constantsRoutes from '../../utils/routing/constants';
import * as endpoints from '../../utils/endpoints';
import { tabsTitles } from '../../utils/texts';
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerIcon from '../components/SpinnerIcon';
import Notification from './components/Notification';
import ModuleHistogramCumulativeScore from '../user/components/ModuleHistogramCumulativeScore';
import ModuleWebChart from '../user/components/ModuleWebChart';
import LazyLoadImage from '../components/LazyLoadImage';
import getBadge from '../user/components/getBadge';
import SecurityModal from './components/SecurityModal';
import Question from '../user/question/Question';
import { buildInitState } from '../user/question/utils/utils';
import useFetchCall from '../../api/useFetchCall';
import NotificationSaved from '../user/components/NotificationSaved';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const percentStrToPercentNum = (string) => {
  return Number((string * 100).toFixed(2));
};

const areElementsEqual = (a, b) => {
  // Check if both are arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
  return a === b;
};

const mainTabs = [{ name: 'Rating' }, { name: 'Form' }, { name: 'Form Comparaison' }];

const emptyScore = {
  missionIndex: 0,
  collisionAvoidance: 0,
  dataSharing: 0,
  detectionIdentificationTracking: 0,
  applicationOfDesignAndOperation: 0,
  externalServices: 0,
  bonus: 0,
  total: 0,
};

const AdminMissionForm = ({ debug = true, form }) => {
  const accessToken = localStorage.getItem('accessToken');
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [mainTab, setMainTab] = useState(mainTabs[0]);
  const [badgeImg, setBadgeImg] = useState(null);

  const [textNotification, setTextNotification] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRequestingChanges, setIsRequestingChanges] = useState(false);
  const [isSecurityModalOpen, setIsSecurityModalOpen] = useState(false);

  const [nameDuplicatedForm, setNameDuplicatedForm] = useState('');

  const { userId, formId } = useParams();

  const [currentTab, setCurrentTab] = useState(tabsTitles[0]);
  const [score, setScore] = useState(emptyScore);
  // Data for MissionIndex
  const [indexAbsolute, setIndexAbsolute] = useState(
    parseFloat(form?.computeParams.indexAbsolute || 0)
  );
  const [indexRelative, setIndexRelative] = useState(
    parseFloat(form?.computeParams.indexRelative || 0)
  );

  const [missionIndexScores, setMissionIndexScores] = useState({
    absolute: 0,
    relative: 0,
  });

  // Data for DetectionIdentificationTracking
  const [optical, setOptical] = useState(parseFloat(form?.computeParams.optical || 0));
  const [radar, setRadar] = useState(parseFloat(form?.computeParams.radar || 0));
  const [passDuration, setPassDuration] = useState(
    parseFloat(form?.computeParams.passDuration || 0)
  );
  const [averageOrbitalCoverage, setAverageOrbitalCoverage] = useState(
    parseFloat(form?.computeParams.averageOrbitalCoverage || 0)
  );
  const [internalDuration, setInternalDuration] = useState(
    parseFloat(form?.computeParams.internalDuration || 0)
  );

  const [missionForm, setMissionForm] = useState(
    debug ? constantsModule.missionForminitState : form.data
  );
  const [adminComments, setAdminComments] = useState('');

  const [baseForm, setBaseForm] = useState({});
  const [baseFormQuestions, setBaseFormQuestions] = useState([]);
  const [comparedToFormQuestions, setComparedToFormQuestions] = useState([]);

  const [formIds, setFormIds] = useState([]);

  const getRequest = {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    computeTotalScore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.computeParams]);

  const [dataRatedForms, loadingRatedForms] = useFetchCall(
    endpoints.USER_RATED_FORMS(userId),
    getRequest
  );

  const [dataBaseForm] = useFetchCall(endpoints.USER_RATED_FORM(baseForm?.id), getRequest);

  useEffect(() => {
    if (dataRatedForms?.data && !loadingRatedForms) {
      setFormIds([...dataRatedForms.data]);
    }
  }, [dataRatedForms, loadingRatedForms]);

  const getQuestionWithAdminComments = (data) => {
    const {
      applicationOfDesignAndOperation,
      collisionAvoidance,
      dataSharing,
      detectionIdentificationTracking,
      externalServices,
      missionIndex,
    } = data;

    const filterQuestionsWithAdminComments = (quest) => quest?.adminComments?.length > 0;

    return [
      ...applicationOfDesignAndOperation.filter(filterQuestionsWithAdminComments),
      ...collisionAvoidance.filter(filterQuestionsWithAdminComments),
      ...dataSharing.filter(filterQuestionsWithAdminComments),
      ...detectionIdentificationTracking.filter(filterQuestionsWithAdminComments),
      ...externalServices.filter(filterQuestionsWithAdminComments),
      ...missionIndex.filter(filterQuestionsWithAdminComments),
    ];
  };

  const getDataQuestionsFlattened = (data) => {
    const {
      applicationOfDesignAndOperation,
      collisionAvoidance,
      dataSharing,
      detectionIdentificationTracking,
      externalServices,
      missionIndex,
    } = data;

    return [
      ...applicationOfDesignAndOperation,
      ...collisionAvoidance,
      ...dataSharing,
      ...detectionIdentificationTracking,
      ...externalServices,
      ...missionIndex,
    ];
  };

  useEffect(() => {
    if (dataBaseForm?.data) {
      const questionsWithAdminComments = getQuestionWithAdminComments(missionForm);
      const baseFormQuestions = getDataQuestionsFlattened(dataBaseForm?.data.data);

      const commonQuestions = [];

      for (const baseQuestion of baseFormQuestions) {
        for (const adminCommentsQuestion of questionsWithAdminComments) {
          // check if both the question text and the choices are equal
          // meaning it's the same question we look for
          const elementsEqual =
            areElementsEqual(baseQuestion.question, adminCommentsQuestion.question) &&
            areElementsEqual(baseQuestion.choices, adminCommentsQuestion.choices);
          const alreadyExists = commonQuestions.includes(baseQuestion);

          if (!alreadyExists && elementsEqual) {
            commonQuestions.push(baseQuestion);
          }
        }
      }
      setBaseFormQuestions(commonQuestions);
      setComparedToFormQuestions(questionsWithAdminComments);
    }
  }, [missionForm, dataBaseForm]);

  useEffect(() => {
    if (score.total >= 0) {
      setBadgeImg(
        getBadge({
          tier_score: score.total,
          bonus_score: score.bonus,
        })
      );
    }
  }, [score]);

  const computeScoreModule = (moduleName) => {
    let verificationValuesArray = 0;
    let meanVerifications = 0;
    switch (moduleName) {
      case 'Mission Index':
        // Flattening the array
        let normalQuestionsMissionIndex = missionForm.missionIndex.filter(
          (item) => !constantsModule.EXTRA_TYPES.includes(item.type)
        );
        const collisionAvoidanceQuestionsMissionIndex = normalQuestionsMissionIndex
          .filter((item) => item.type === constantsModule.RADIO_HIDE_COMPONENTS)
          .filter((item) => item.answer === 'Yes')
          .map((item) => item.components)
          .flat();
        normalQuestionsMissionIndex = normalQuestionsMissionIndex.filter(
          (item) => item.type !== constantsModule.RADIO_HIDE_COMPONENTS
        );

        if (collisionAvoidanceQuestionsMissionIndex.length > 0)
          normalQuestionsMissionIndex = [
            ...normalQuestionsMissionIndex,
            ...collisionAvoidanceQuestionsMissionIndex,
          ];
        // Flattening the array
        verificationValuesArray = normalQuestionsMissionIndex.map((item) => {
          return constantsModule.verificationLevelsWeights[
            constantsModule.verificationLevels.findIndex((verif) => verif === item.verification)
          ];
        });
        meanVerifications =
          verificationValuesArray.reduce((a, b) => a + b, 0) / verificationValuesArray.length;

        const deploymentDuration = missionForm.missionIndex.find(
          (item) => item.question === 'Deployment duration (years)'
        ).answer;

        const scoresMissionIndex = constantsModule.getScoreMissionIndex({
          indexAbsolute: parseFloat(indexAbsolute),
          indexRelative: parseFloat(indexRelative),
          deploymentDuration: parseFloat(deploymentDuration),
          meanVerifications: parseFloat(meanVerifications),
        });

        setMissionIndexScores({
          absolute: Number.isNaN(scoresMissionIndex[0]) ? 0 : scoresMissionIndex[0],
          relative: Number.isNaN(scoresMissionIndex[1]) ? 0 : scoresMissionIndex[1],
        });
        return Number.isNaN(scoresMissionIndex[2]) ? 0 : scoresMissionIndex[2];
      case 'Collision Avoidance Capabilities':
        const normalQuestionsCollissionAvoidance = missionForm.collisionAvoidance.filter(
          (item) => !constantsModule.EXTRA_TYPES.includes(item.type)
        );
        const scoreCollisionAvoidance = constantsModule.getScoreCollisionAvoidance(
          normalQuestionsCollissionAvoidance
        );

        return Number.isNaN(scoreCollisionAvoidance) ? 0 : scoreCollisionAvoidance;
      case 'Data Sharing':
        // Flattening the array
        let normalQuestionsDataSharing = missionForm.dataSharing.filter(
          (item) => !constantsModule.EXTRA_TYPES.includes(item.type)
        );
        const autonomousQuestionsDataSharing = normalQuestionsDataSharing
          .filter((item) => item.type === constantsModule.RADIO_HIDE_COMPONENTS)
          .filter((item) => item.answer === 'Yes')
          .map((item) => item.components)
          .flat();
        normalQuestionsDataSharing = normalQuestionsDataSharing.filter(
          (item) => item.type !== constantsModule.RADIO_HIDE_COMPONENTS
        );

        if (autonomousQuestionsDataSharing.length > 0)
          normalQuestionsDataSharing = [
            ...normalQuestionsDataSharing,
            ...autonomousQuestionsDataSharing,
          ];
        // Flattening the array

        const scoreDataSharing = constantsModule.getScoreDataSharing(normalQuestionsDataSharing);

        return Number.isNaN(scoreDataSharing) ? 0 : scoreDataSharing;
      case 'Detection Identification Tracking':
        let normalQuestionsDetectionIdentificationTracking =
          missionForm.detectionIdentificationTracking
            .filter(
              (item) =>
                ![...constantsModule.EXTRA_TYPES, constantsModule.DROPDOWN].includes(item.type)
            )
            .filter((item) => item.question !== constantsModule.QUALITATIVE_SCORE_QUESTION);

        verificationValuesArray = normalQuestionsDetectionIdentificationTracking.map((item) => {
          return constantsModule.verificationLevelsWeights[
            constantsModule.verificationLevels.findIndex((verif) => verif === item.verification)
          ];
        });
        meanVerifications =
          verificationValuesArray.reduce((a, b) => a + b, 0) / verificationValuesArray.length;

        const qualitativeQuestion = missionForm.detectionIdentificationTracking.find(
          (item) =>
            item.question ===
            'Do you track the resident space objects you operate? Select all that apply. Resident space object is tracked:'
        );
        const pointsQualitative = qualitativeQuestion.answer
          .map((answer, index) => {
            if (answer) {
              return (
                qualitativeQuestion.answerWeights[index] *
                constantsModule.verificationLevelsWeights[
                  constantsModule.verificationLevels.findIndex(
                    (verif) => verif === qualitativeQuestion.verification
                  )
                ]
              );
            }
            return 0;
          })
          .reduce((a, b) => a + b, 0);
        const maxPointsQualitative = qualitativeQuestion.answerWeights.reduce((a, b) => a + b, 0);
        const qualitativeScore = pointsQualitative / maxPointsQualitative;

        const scoreDetectionIdentificationTracking =
          constantsModule.getScoreDetectionIdentificationTracking({
            meanVerifications: parseFloat(meanVerifications),
            optical: parseFloat(optical),
            radar: parseFloat(radar),
            passDuration: parseFloat(passDuration),
            averageOrbitalCoverage: parseFloat(averageOrbitalCoverage),
            internalDuration: parseFloat(internalDuration),
            qualitativeScore: parseFloat(qualitativeScore),
          });

        return Number.isNaN(scoreDetectionIdentificationTracking)
          ? 0
          : scoreDetectionIdentificationTracking;
      case 'Application of Design and Operation Standards':
        let normalQuestionsApplicationOfDesignAndOperation =
          missionForm.applicationOfDesignAndOperation.filter(
            (item) => !constantsModule.EXTRA_TYPES.includes(item.type)
          );

        // Flattener part for hide questions
        const proximityRendezVousQuestions = normalQuestionsApplicationOfDesignAndOperation
          .filter((item) => item.type === constantsModule.RADIO_HIDE_COMPONENTS)
          .filter((item) => item.answer === 'Yes')
          .map((item) => item.components)
          .flat();
        normalQuestionsApplicationOfDesignAndOperation =
          normalQuestionsApplicationOfDesignAndOperation.filter(
            (item) => item.type !== constantsModule.RADIO_HIDE_COMPONENTS
          );

        if (proximityRendezVousQuestions.length > 0)
          normalQuestionsApplicationOfDesignAndOperation = [
            ...normalQuestionsApplicationOfDesignAndOperation,
            ...proximityRendezVousQuestions,
          ];
        // Flattener part

        let allComplianceQuestionsApplicationOfDesignAndOperation =
          missionForm.applicationOfDesignAndOperation
            .filter((item) => !constantsModule.OTHER_TYPES.includes(item.type))
            .filter((item) => {
              return constantsModule.COMPLIANCE_QUESTIONS.includes(item.question[0]);
            });

        const otherQuestionApplicationOfDesignAndOperation =
          missionForm.applicationOfDesignAndOperation
            .filter(
              (item) =>
                item.question ===
                'Does your mission include close proximity or rendez vous operations?'
            )
            .filter((item) => item.answer === 'Yes')
            .map((item) => item.components)
            .flat();

        if (otherQuestionApplicationOfDesignAndOperation.length > 0)
          allComplianceQuestionsApplicationOfDesignAndOperation.push(
            ...otherQuestionApplicationOfDesignAndOperation
          );

        let tailor = allComplianceQuestionsApplicationOfDesignAndOperation.map((item) =>
          Number(item.answerInput)
        );

        const normalRecommendedQuestions = missionForm.applicationOfDesignAndOperation.filter(
          (item) => item.type === constantsModule.RADIO_CONDITION_VERIF
        );

        // Gets the passivation and explosion questions
        const passivationOrExplosionQuestions =
          normalQuestionsApplicationOfDesignAndOperation.filter(
            (item) => item.type === constantsModule.RADIO_CONDITION_INPUT_VERIF
          );
        const explosionQuestion = passivationOrExplosionQuestions.find(
          (item) =>
            item.question[0] ===
            'Level of minimization of the probability of explosion as part of the operational lifetime'
        );
        const passivationQuestions = passivationOrExplosionQuestions.filter(
          (item) =>
            item.question[0] !==
              'Level of minimization of the probability of explosion as part of the operational lifetime' &&
            !constantsModule.COMPLIANCE_QUESTIONS.includes(item.question[0])
        );

        const allScoresApplicationOfDesignAndOperationScore = [
          ...constantsModule.getComplianceQuestionsApplicationOfDesignAndOperationScore(
            allComplianceQuestionsApplicationOfDesignAndOperation,
            tailor
          ),
          ...constantsModule.getNormalQuestionsApplicationOfDesignAndOperationScore(
            normalRecommendedQuestions
          ),
          constantsModule.getExplosionScore({ question: explosionQuestion }),
          ...constantsModule.getPassivationScore({ questions: passivationQuestions }),
        ];

        const maxPoints = [
          ...allComplianceQuestionsApplicationOfDesignAndOperation,
          ...normalRecommendedQuestions,
          explosionQuestion,
          ...passivationQuestions,
        ]
          .map((item) => Math.max(...item.answerWeights))
          .reduce((a, b) => a + b, 0);

        const scoreApplicationOfDesignAndOperation =
          allScoresApplicationOfDesignAndOperationScore.reduce((a, b) => a + b, 0) / maxPoints;
        return Number.isNaN(scoreApplicationOfDesignAndOperation)
          ? 0
          : scoreApplicationOfDesignAndOperation;
      case 'External Services':
        const sumPointsExternalServices = missionForm.externalServices
          .filter((item) => !constantsModule.OTHER_TYPES.includes(item.type))
          .map((item) => {
            const indexAnswer = item.choices.findIndex((choice) => choice === item.answer);
            return (
              item.answerWeights[indexAnswer] *
              constantsModule.verificationLevelsWeights[
                constantsModule.verificationLevels.findIndex((verif) => verif === item.verification)
              ]
            );
          });
        const maxPointsExternalServices = missionForm.externalServices
          .filter((item) => !constantsModule.OTHER_TYPES.includes(item.type))
          .map((item) => {
            if (Array.isArray(item.answer)) return item.answerWeights.reduce((a, b) => a + b, 0);
            return Math.max(...item.answerWeights);
          });

        const scoreExternalServices =
          sumPointsExternalServices
            .map((item, index) => item / maxPointsExternalServices[index])
            .reduce((a, b) => a + b, 0) / maxPointsExternalServices.length;

        return Number.isNaN(scoreExternalServices) ? 0 : scoreExternalServices;
      default:
        break;
    }
  };

  const computeTotalScore = () => {
    const computedScores = {
      missionIndex: computeScoreModule('Mission Index'),
      collisionAvoidance: computeScoreModule('Collision Avoidance Capabilities'),
      dataSharing: computeScoreModule('Data Sharing'),
      detectionIdentificationTracking: computeScoreModule('Detection Identification Tracking'),
      applicationOfDesignAndOperation: computeScoreModule(
        'Application of Design and Operation Standards'
      ),
      externalServices: computeScoreModule('External Services'),
    };
    const totalScore =
      0.5 * computedScores.missionIndex +
      0.165 * computedScores.collisionAvoidance +
      0.165 * computedScores.dataSharing +
      0.12 * computedScores.detectionIdentificationTracking +
      0.05 * computedScores.applicationOfDesignAndOperation;

    let possibleBonusApplicationOfDesignAndOperation = missionForm.applicationOfDesignAndOperation
      .filter((item) => !constantsModule.OTHER_TYPES.includes(item.type))
      .filter((item) => {
        return (
          constantsModule.COMPLIANCE_QUESTIONS.includes(item.question[0]) &&
          item.answerRadio === 'Compliant, voluntary adopted'
        );
      });

    const additionalQuestionApplicationOfDesignAndOperation =
      missionForm.applicationOfDesignAndOperation
        .filter(
          (item) =>
            item.question === 'Does your mission include close proximity or rendez vous operations?'
        )
        .filter((item) => item.answer === 'Yes')
        .map((item) => item.components)
        .flat()
        .filter((item) => item.answerRadio === 'Compliant, voluntary adopted');

    let tailor = possibleBonusApplicationOfDesignAndOperation.map((item) =>
      Number(item.answerInput)
    );

    if (additionalQuestionApplicationOfDesignAndOperation.length > 0)
      possibleBonusApplicationOfDesignAndOperation.push(
        ...additionalQuestionApplicationOfDesignAndOperation
      );

    let bonusQuestions = [
      ...missionForm.collisionAvoidance,
      ...missionForm.dataSharing,
      ...missionForm.externalServices,
      ...missionForm.detectionIdentificationTracking,
    ].filter((item) => constantsModule.BONUS_TYPES.includes(item.type));

    const bonusScore = constantsModule.getBonusScore(
      bonusQuestions,
      possibleBonusApplicationOfDesignAndOperation,
      tailor
    );

    setScore({
      ...computedScores,
      bonus: Number.isNaN(bonusScore) ? 0 : bonusScore,
      total: Number.isNaN(totalScore) ? 0 : totalScore,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const savedMissionFormRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: formId,
        user: form.user,
        name: form.name,
        version: endpoints.APP_VERSION,
        data: missionForm,
        computeParams: {
          indexAbsolute,
          indexRelative,
          optical,
          radar,
          passDuration,
          averageOrbitalCoverage,
          internalDuration,
        },
      }),
    };

    try {
      setIsFetching(true);
      const response = await fetch(endpoints.API + endpoints.SAVE_FORM, savedMissionFormRequest);
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        // Display success
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 1500);
      }
    } catch (err) {
      console.log(err);
    }
    setIsFetching(false);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();

    const submittedMissionFormRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: formId,
        user: form.user,
        name: form.name,
        adminComments: adminComments,
        version: endpoints.APP_VERSION,
        data: missionForm,
        ratedData: score,
        computeParams: {
          indexAbsolute,
          indexRelative,
          optical,
          radar,
          passDuration,
          averageOrbitalCoverage,
          internalDuration,
        },
      }),
    };

    try {
      setIsSubmitting(true);
      setIsFetching(true);
      const response = await fetch(
        endpoints.API + endpoints.RATE_FORM,
        submittedMissionFormRequest
      );
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        setScore({
          missionIndex: 0,
          collisionAvoidance: 0,
          dataSharing: 0,
          detectionIdentificationTracking: 0,
          applicationOfDesignAndOperation: 0,
          bonus: 0,
          total: 0,
        });

        setShowNotification(true);
        setTextNotification('Score successfully submitted!');
        setTimeout(() => {
          setShowNotification(false);
          setTextNotification('');
          navigate(constantsRoutes.ROUTE_USER_FORMS(userId));
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
    setIsSubmitting(false);
    setIsFetching(false);
  };

  const handleChangesRequested = async (e) => {
    e.preventDefault();

    const changesRequestedMissionFormRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: formId,
        user: form.user,
        name: nameDuplicatedForm !== '' ? nameDuplicatedForm : form.name,
        version: endpoints.APP_VERSION,
        data: missionForm,
      }),
    };

    try {
      setIsRequestingChanges(true);
      setIsFetching(true);
      const response = await fetch(
        endpoints.API + endpoints.CHANGES_REQUESTED_FORM,
        changesRequestedMissionFormRequest
      );
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        setShowNotification(true);
        setTextNotification('Requested Changes successfully sent!');
        setTimeout(() => {
          setShowNotification(false);
          setTextNotification('');
          navigate(constantsRoutes.ROUTE_USER_FORMS(userId));
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
    setIsRequestingChanges(false);
    setIsFetching(false);
  };

  const handleDuplicateRequested = async (e) => {
    e.preventDefault();

    const changesDuplicateMissionFormRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: formId,
        user: form.user,
        name: nameDuplicatedForm,
        version: endpoints.APP_VERSION,
        data: missionForm,
      }),
    };

    try {
      setIsRequestingChanges(true);
      setIsFetching(true);
      const response = await fetch(
        endpoints.API + endpoints.DUPLICATE_REQUESTED_FORM,
        changesDuplicateMissionFormRequest
      );
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        setShowNotification(true);
        setTextNotification('Duplicated Form successfully saved!');
        setTimeout(() => {
          setShowNotification(false);
          setTextNotification('');
          navigate(constantsRoutes.ROUTE_USER_FORMS(userId));
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
    setIsRequestingChanges(false);
    setIsFetching(false);
  };

  const handleSendDuplicate = async (e) => {
    e.preventDefault();

    const changesDuplicateMissionFormRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: formId,
        user: form.user,
        name: form.name,
        version: endpoints.APP_VERSION,
        data: missionForm,
      }),
    };

    try {
      setIsRequestingChanges(true);
      setIsFetching(true);
      const response = await fetch(
        endpoints.API + endpoints.DUPLICATE_SEND_FORM,
        changesDuplicateMissionFormRequest
      );
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        setShowNotification(true);
        setTextNotification('Duplicated Form successfully sent!');
        setTimeout(() => {
          setShowNotification(false);
          setTextNotification('');
          navigate(constantsRoutes.ROUTE_USER_FORMS(userId));
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
    setIsRequestingChanges(false);
    setIsFetching(false);
  };

  const displayComparaisonTable = () => {
    return (
      <table className="table-fixed border-collapse border-gray-300 my-4">
        <thead>
          <tr>
            <th className="border border-gray-300">Question</th>
            <th className="border border-gray-300">User answer</th>
            <th className="border border-gray-300">Admin Recommendations</th>
            <th className="border border-gray-300">Comments</th>
          </tr>
        </thead>
        <tbody>
          {React.Children.toArray(
            baseFormQuestions.map((q, i) => (
              <tr>
                <td className="border p-2 border-gray-300">
                  <Question
                    displayType={constantsModule.DISPLAY_QUESTION}
                    question={buildInitState(q)}
                    handleChanges={() => {}}
                  />
                </td>
                <td className="border p-2 border-gray-300">
                  <Question
                    displayType={constantsModule.DISPLAY_ANSWERS}
                    question={buildInitState(q)}
                    handleChanges={() => {}}
                  />
                </td>
                <td className="border p-2 border-gray-300">
                  <Question
                    displayType={constantsModule.DISPLAY_ANSWERS}
                    question={buildInitState(comparedToFormQuestions[i])}
                    handleChanges={() => {}}
                  />
                </td>
                <td className="border p-2 border-gray-300">
                  {comparedToFormQuestions[i].adminComments}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  };

  const DisplayScoreModule = () => {
    switch (currentTab) {
      case 'Mission Index':
        return (
          <>
            <div className="flex flex-wrap">
              <div>
                <label>Index Absolute</label>
                <input
                  type="text"
                  name="indexAbsolute"
                  value={indexAbsolute}
                  onChange={(e) => setIndexAbsolute(e.target.value)}
                  className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label>Index Relative</label>
                <input
                  type="text"
                  name="indexRelative"
                  value={indexRelative}
                  onChange={(e) => setIndexRelative(e.target.value)}
                  className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <div>Absolute Score: {missionIndexScores.absolute}</div>
              <div>Relative Score: {missionIndexScores.relative}</div>
            </div>

            <div>Mission Index: {score.missionIndex}</div>
          </>
        );
      case 'Collision Avoidance Capabilities':
        return <div>Collision Avoidance Capabilities: {score.collisionAvoidance}</div>;
      case 'Data Sharing':
        return <div>Data Sharing: {score.dataSharing}</div>;
      case 'Detection Identification Tracking':
        return (
          <>
            <div className="flex flex-wrap">
              <div>
                <label>Optical</label>
                <input
                  type="text"
                  name="optical"
                  value={optical}
                  onChange={(e) => setOptical(e.target.value)}
                  className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label>Radar</label>
                <input
                  type="text"
                  name="radar"
                  value={radar}
                  onChange={(e) => setRadar(e.target.value)}
                  className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label>Pass Duration</label>
                <input
                  type="text"
                  name="passDuration"
                  value={passDuration}
                  onChange={(e) => setPassDuration(e.target.value)}
                  className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label>Average Orbital Coverage</label>
                <input
                  type="text"
                  name="averageOrbitalCoverage"
                  value={averageOrbitalCoverage}
                  onChange={(e) => setAverageOrbitalCoverage(e.target.value)}
                  className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label>Internal Duration</label>
                <input
                  type="text"
                  name="internalDuration"
                  value={internalDuration}
                  onChange={(e) => setInternalDuration(e.target.value)}
                  className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>Detection Identification Tracking: {score.detectionIdentificationTracking}</div>
          </>
        );
      case 'Application of Design and Operation Standards':
        return (
          <>
            <div>Application Of Design And Operation: {score.applicationOfDesignAndOperation}</div>
          </>
        );

      case 'External Services':
        return <div>External Services: {score.externalServices}</div>;
      default:
        break;
    }
  };

  const SwitchMainTabs = () => {
    switch (mainTab.name) {
      case 'Rating':
        return (
          <>
            <div className="flex items-center justify-center pb-2">
              <div className="flex items-center justify-center first-letter:bg-gray-200 shadow rounded-lg w-96">
                <LazyLoadImage className="h-[200px]" src={badgeImg} alt="badge" />
              </div>
            </div>
            <dl className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">Tier Score</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {percentStrToPercentNum(score.total)} %
                </dd>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">Bonus Score</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {percentStrToPercentNum(score.bonus)} %
                </dd>
              </div>
            </dl>

            <dl className="mt-5 mb-1 grid grid-cols-1 rounded-t-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">Mission Index</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.missionIndex)} %
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">
                  Collision Avoidance Capabilities
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.collisionAvoidance)} %
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">Data Sharing</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.dataSharing)} %
                  </div>
                </dd>
              </div>
            </dl>

            <dl className="mb-5 grid grid-cols-1 rounded-b-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">
                  Detection, Identification and Tracking
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.detectionIdentificationTracking)} %
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-sm text-gray-900">
                  Application of Design and Operation Standards
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.applicationOfDesignAndOperation)} %
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">External Services</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.externalServices)} %
                  </div>
                </dd>
              </div>
            </dl>
            <div className="grid grid-cols-2 pb-8">
              <ModuleWebChart results={[{ name: form?.name, score }]} />
              <ModuleHistogramCumulativeScore results={[{ name: form?.name, score }]} />
            </div>
          </>
        );
      case 'Form':
        return MissionForm({
          currentTab,
          setCurrentTab,
          tabsTitles,
          missionForm,
          setMissionForm,
          displayType: !debug
            ? constantsModule.DISPLAY_USER_COMMENTS_AND_INPUT_COMMENTS_TO_ADMIN
            : constantsModule.DISPLAY_NO_COMMENTS,
        });
      case 'Form Comparaison':
        return displayComparaisonTable();
      default:
        break;
    }
  };

  const handleChange = (setHandler) => (e) => {
    setHandler(formIds.find(({ id }) => e.target.value === id));
  };

  const DisplayTabs = () => {
    return (
      <nav className="-mb-px flex space-x-8">
        {React.Children.toArray(
          mainTabs.map((tab) => (
            <div
              onClick={() => setMainTab(tab)}
              className={classNames(
                mainTab.name === tab.name
                  ? 'border-red-500 text-red-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'cursor-pointer whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
              )}>
              {tab.name}
            </div>
          ))
        )}
      </nav>
    );
  };

  return (
    <>
      <NotificationSaved show={show} setShow={setShow} />
      <SecurityModal
        message="Are you sure you want to submit the rating to the SSR applicant?"
        isOpen={isSecurityModalOpen}
        setIsOpen={setIsSecurityModalOpen}
        onClickFunc={handleSubmit}
      />
      <Notification show={showNotification} setShow={setShowNotification} text={textNotification} />
      <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pl-4 pt-8">
        <>
          {!debug && (
            <div>
              <label>Admin Comments</label>
              <textarea
                id="adminComments"
                name="admin Comments"
                placeholder="Add the comments you want to send to the operator's form"
                value={adminComments}
                onChange={(e) => setAdminComments(e.target.value)}
                rows={3}
                className="my-1 block w-1/3 rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
              />

              <label>Name duplicated Form</label>
              <input
                type="text"
                name="nameDuplicatedForm"
                value={nameDuplicatedForm}
                onChange={(e) => setNameDuplicatedForm(e.target.value)}
                className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          )}

          <div className="font-medium">Bonus: {score.bonus}</div>
          <div className="font-medium pb-3">Total: {score.total}</div>

          {DisplayScoreModule()}
        </>
        <div className="flex flex-col">
          <span className="mt-1">
            <button
              type="button"
              onClick={computeTotalScore}
              disabled={isFetching}
              className="inline-flex mr-2 items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600">
              <GlobeAltIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
              Compute Scores
            </button>
            {!debug && (
              <div className="pt-2">
                <button
                  type="button"
                  onClick={handleSave}
                  disabled={isFetching}
                  className={`inline-flex items-center mr-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600`}>
                  {isFetching ? (
                    <SpinnerIcon />
                  ) : (
                    <SaveIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                  )}
                  Save Rating Score
                </button>
                <button
                  type="button"
                  onClick={() => setIsSecurityModalOpen(true)}
                  disabled={isFetching}
                  className={` ${
                    isFetching ? 'bg-gray-400' : 'bg-gray-500'
                  } inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600`}>
                  {isSubmitting ? (
                    <SpinnerIcon />
                  ) : (
                    <CloudUploadIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
                  )}
                  Submit Scores
                </button>
                <button
                  type="button"
                  onClick={handleChangesRequested}
                  disabled={isFetching}
                  className={` ${
                    isFetching ? 'bg-gray-400' : 'bg-gray-500'
                  } inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600`}>
                  {isRequestingChanges ? (
                    <SpinnerIcon />
                  ) : (
                    <RefreshIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
                  )}
                  Request Changes
                </button>
                <button
                  type="button"
                  onClick={handleDuplicateRequested}
                  disabled={isFetching}
                  className={` ${
                    isFetching ? 'bg-gray-400' : 'bg-gray-500'
                  } inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600`}>
                  {isRequestingChanges ? (
                    <SpinnerIcon />
                  ) : (
                    <DocumentDuplicateIcon
                      className="-ml-1 mr-2 h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  )}
                  Duplicate Form
                </button>
                <button
                  type="button"
                  onClick={handleSendDuplicate}
                  disabled={isFetching}
                  className={` ${
                    isFetching ? 'bg-gray-400' : 'bg-gray-500'
                  } inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600`}>
                  {isRequestingChanges ? (
                    <SpinnerIcon />
                  ) : (
                    <ArrowCircleDownIcon
                      className="-ml-1 mr-2 h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  )}
                  Send Duplicate Form
                </button>
              </div>
            )}
          </span>
        </div>
        <div className="border-b border-gray-200 my-4">
          <div className="sm:flex sm:items-baseline ">
            <h3 className="text-lg leading-6 pl-4 font-medium text-gray-900 mr-6">{form?.name}</h3>
            {formId && formIds.filter(({ id }) => formId !== id).length > 0 && (
              <select
                name="baseForm"
                value={baseForm?.id || ''}
                onChange={handleChange(setBaseForm)}
                className="mt-1 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md">
                <option value="" label="Base form" />
                {React.Children.toArray(
                  formIds
                    .filter(({ id }) => formId !== id)
                    .map(({ id, name }) => <option value={id}>{name}</option>)
                )}
              </select>
            )}
            <div className="mt-4 ml-6 sm:mt-0">{DisplayTabs()}</div>
          </div>
        </div>
        {SwitchMainTabs()}
      </div>
    </>
  );
};

export default AdminMissionForm;
