import React, { useEffect, useState } from 'react';

import useAuth from '../../hooks/useAuth';
import SpinnerIcon from '../components/SpinnerIcon';
import * as endpoints from '../../utils/endpoints';
import { CheckIcon } from '@heroicons/react/solid';
import { QuestionMarkCircleIcon, SaveIcon } from '@heroicons/react/outline';

import * as constantsModule from '../../utils/modules/constants';
import { tabsTitles } from '../../utils/texts';
import * as constantsRoute from '../../utils/routing/constants';
import MissionForm from './components/MissionForm';
import useValidateMissionForm from './question/schema/useValidateMissionForm';
import { useNavigate } from 'react-router-dom';
import GeneralInstructionsModal from './components/GeneralInstructionsModal';
import SecurityModal from './components/SecurityModal';

const emptyComputeParams = {
  indexAbsolute: 0,
  indexRelative: 0,
  optical: 0,
  radar: 0,
  passDuration: 0,
  averageOrbitalCoverage: 0,
  internalDuration: 0,
};

const UserMissionForm = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');

  const [isGeneralInstructionsOpen, setIsGeneralInstructionsOpen] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const [currentTab, setCurrentTab] = useState(tabsTitles[0]);
  const [missionName, setMissionName] = useState('');
  const [missionForm, setMissionForm] = useState(constantsModule.missionForminitState);
  const [isSecurityModalOpen, setIsSecurityModalOpen] = useState(false);

  const errors = useValidateMissionForm({ missionForm });

  useEffect(() => {
    return () => {
      setIsFetching(false);
      setCurrentTab(tabsTitles[0]);
      setMissionName('');
      setMissionForm(constantsModule.missionForminitState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();

    const savedMissionFormRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: auth.id,
        name: missionName,
        version: endpoints.APP_VERSION,
        data: missionForm,
        computeParams: emptyComputeParams,
      }),
    };

    try {
      setIsFetching(true);
      const response = await fetch(endpoints.API + endpoints.SAVE_FORM, savedMissionFormRequest);
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        const res = await response.json();
        navigate(constantsRoute.ROUTE_USER_SAVED_FORM(res.data.user, res.data.id));
      }
    } catch (err) {
      console.log(err);
    }
    setIsFetching(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newMissionFormRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: auth.id,
        name: missionName,
        version: endpoints.APP_VERSION,
        data: missionForm,
      }),
    };

    try {
      setIsFetching(true);
      const response = await fetch(endpoints.API + endpoints.SUBMIT_FORM, newMissionFormRequest);
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        const res = await response.json();
        navigate(constantsRoute.ROUTE_USER_FORM(res.data.user, res.data.id));
      }
    } catch (err) {
      console.log(err);
    }
    setIsFetching(false);
  };

  return (
    <>
      <SecurityModal
        message="By clicking submit, I accept that the information contained in this form is sent to the SSR applicant. I confirm that the information provided about my mission is correct and accurate."
        isOpen={isSecurityModalOpen}
        setIsOpen={setIsSecurityModalOpen}
        onClickFunc={handleSubmit}
      />
      <GeneralInstructionsModal
        open={isGeneralInstructionsOpen}
        setOpen={setIsGeneralInstructionsOpen}
      />
      <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pl-4 pt-8">
        <div className="pb-4 text-2xl font-bold font-sans">
          Create Mission
          <button
            type="button"
            className="items-center pl-2 rounded-full text-gray"
            onClick={() => {
              setIsGeneralInstructionsOpen(true);
            }}>
            <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="flex">
          <input
            name={'missionName'}
            type="text"
            placeholder="Mission Name"
            onChange={(e) => setMissionName(e.target.value)}
            value={missionName}
            className="mt-1 block w-80 focus:ring-gray-600 focus:border-gray-600 shadow-sm sm:text-sm border-gray-300 rounded-md"
          />

          <span className="mt-1 sm:ml-3">
            <button
              type="button"
              onClick={handleSave}
              disabled={missionName.length === 0 || isFetching}
              className={` ${
                missionName.length === 0 && 'opacity-40'
              } inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600`}>
              {isFetching ? (
                <SpinnerIcon />
              ) : (
                <SaveIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
              )}
              Save
            </button>
          </span>

          <span className="mt-1 sm:ml-3">
            <button
              type="button"
              onClick={() => setIsSecurityModalOpen(true)}
              disabled={missionName.length === 0 || errors.length !== 0 || isFetching}
              className={` ${
                errors.length !== 0 && 'opacity-40'
              } inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600`}>
              {isFetching ? (
                <SpinnerIcon />
              ) : (
                <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              )}
              Submit
            </button>
          </span>
        </div>

        {MissionForm({
          currentTab,
          setCurrentTab,
          tabsTitles,
          missionForm,
          setMissionForm,
          displayType: constantsModule.DISPLAY_INPUT_COMMENTS_TO_USER,
        })}
      </div>
    </>
  );
};

export default UserMissionForm;
