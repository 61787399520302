import { useState } from 'react';

import { tabsTitles } from '../../utils/texts';
import * as constantsModule from '../../utils/modules/constants';
import MissionForm from './components/MissionForm';

const UserSummaryForm = ({ missionForm }) => {
  const [currentTab, setCurrentTab] = useState(tabsTitles[0]);

  return (
    <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pl-4 pt-8">
      {MissionForm({
        currentTab,
        setCurrentTab,
        tabsTitles,
        missionForm,
        setMissionForm: () => {},
        displayType: constantsModule.DISPLAY_INPUT_COMMENTS_AND_INPUT_ANSWERS_TO_USER,
      })}
    </div>
  );
};

export default UserSummaryForm;
