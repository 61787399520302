import * as constantsModule from './constants';

const computeTotalScoreModule = (form) => {
  const { data: missionForm, computeParams } = form?.data;

  let verificationValuesArray = 0;
  let meanVerifications = 0;

  // Flattening the array
  let normalQuestionsMissionIndex = missionForm.missionIndex.filter(
    (item) => !constantsModule.EXTRA_TYPES.includes(item.type)
  );
  const collisionAvoidanceQuestionsMissionIndex = normalQuestionsMissionIndex
    .filter((item) => item.type === constantsModule.RADIO_HIDE_COMPONENTS)
    .filter((item) => item.answer === 'Yes')
    .map((item) => item.components)
    .flat();
  normalQuestionsMissionIndex = normalQuestionsMissionIndex.filter(
    (item) => item.type !== constantsModule.RADIO_HIDE_COMPONENTS
  );

  if (collisionAvoidanceQuestionsMissionIndex.length > 0)
    normalQuestionsMissionIndex = [
      ...normalQuestionsMissionIndex,
      ...collisionAvoidanceQuestionsMissionIndex,
    ];
  // Flattening the array
  verificationValuesArray = normalQuestionsMissionIndex.map((item) => {
    return constantsModule.verificationLevelsWeights[
      constantsModule.verificationLevels.findIndex((verif) => verif === item.verification)
    ];
  });
  meanVerifications =
    verificationValuesArray.reduce((a, b) => a + b, 0) / verificationValuesArray.length;

  const deploymentDuration = missionForm.missionIndex.find(
    (item) => item.question === 'Deployment duration (years)'
  ).answer;

  const scoresMissionIndex = constantsModule.getScoreMissionIndex({
    indexAbsolute: parseFloat(computeParams.indexAbsolute),
    indexRelative: parseFloat(computeParams.indexRelative),
    deploymentDuration: parseFloat(deploymentDuration),
    meanVerifications: parseFloat(meanVerifications),
  });

  const scoreMI = Number.isNaN(scoresMissionIndex[2]) ? 0 : scoresMissionIndex[2];
  const normalQuestionsCollissionAvoidance = missionForm.collisionAvoidance.filter(
    (item) => !constantsModule.EXTRA_TYPES.includes(item.type)
  );
  const scoreCollisionAvoidance = constantsModule.getScoreCollisionAvoidance(
    normalQuestionsCollissionAvoidance
  );

  const scoreCOLLA = Number.isNaN(scoreCollisionAvoidance) ? 0 : scoreCollisionAvoidance;

  // Flattening the array
  let normalQuestionsDataSharing = missionForm.dataSharing.filter(
    (item) => !constantsModule.EXTRA_TYPES.includes(item.type)
  );
  const autonomousQuestionsDataSharing = normalQuestionsDataSharing
    .filter((item) => item.type === constantsModule.RADIO_HIDE_COMPONENTS)
    .filter((item) => item.answer === 'Yes')
    .map((item) => item.components)
    .flat();
  normalQuestionsDataSharing = normalQuestionsDataSharing.filter(
    (item) => item.type !== constantsModule.RADIO_HIDE_COMPONENTS
  );

  if (autonomousQuestionsDataSharing.length > 0)
    normalQuestionsDataSharing = [...normalQuestionsDataSharing, ...autonomousQuestionsDataSharing];
  // Flattening the array

  const scoreDataSharing = constantsModule.getScoreDataSharing(normalQuestionsDataSharing);

  const scoreDS = Number.isNaN(scoreDataSharing) ? 0 : scoreDataSharing;

  let normalQuestionsDetectionIdentificationTracking = missionForm.detectionIdentificationTracking
    .filter(
      (item) => ![...constantsModule.EXTRA_TYPES, constantsModule.DROPDOWN].includes(item.type)
    )
    .filter((item) => item.question !== constantsModule.QUALITATIVE_SCORE_QUESTION);

  verificationValuesArray = normalQuestionsDetectionIdentificationTracking.map((item) => {
    return constantsModule.verificationLevelsWeights[
      constantsModule.verificationLevels.findIndex((verif) => verif === item.verification)
    ];
  });
  meanVerifications =
    verificationValuesArray.reduce((a, b) => a + b, 0) / verificationValuesArray.length;

  const qualitativeQuestion = missionForm.detectionIdentificationTracking.find(
    (item) =>
      item.question ===
      'Do you track the resident space objects you operate? Select all that apply. Resident space object is tracked:'
  );
  const pointsQualitative = qualitativeQuestion.answer
    .map((answer, index) => {
      if (answer) {
        return (
          qualitativeQuestion.answerWeights[index] *
          constantsModule.verificationLevelsWeights[
            constantsModule.verificationLevels.findIndex(
              (verif) => verif === qualitativeQuestion.verification
            )
          ]
        );
      }
      return 0;
    })
    .reduce((a, b) => a + b, 0);
  const maxPointsQualitative = qualitativeQuestion.answerWeights.reduce((a, b) => a + b, 0);
  const qualitativeScore = pointsQualitative / maxPointsQualitative;

  const scoreDetectionIdentificationTracking =
    constantsModule.getScoreDetectionIdentificationTracking({
      meanVerifications: parseFloat(meanVerifications),
      optical: parseFloat(computeParams.optical),
      radar: parseFloat(computeParams.radar),
      passDuration: parseFloat(computeParams.passDuration),
      averageOrbitalCoverage: parseFloat(computeParams.averageOrbitalCoverage),
      internalDuration: parseFloat(computeParams.internalDuration),
      qualitativeScore: parseFloat(qualitativeScore),
    });

  const scoreDIT = Number.isNaN(scoreDetectionIdentificationTracking)
    ? 0
    : scoreDetectionIdentificationTracking;

  let normalQuestionsApplicationOfDesignAndOperation =
    missionForm.applicationOfDesignAndOperation.filter(
      (item) => !constantsModule.EXTRA_TYPES.includes(item.type)
    );

  // Flattener part for hide questions
  const proximityRendezVousQuestions = normalQuestionsApplicationOfDesignAndOperation
    .filter((item) => item.type === constantsModule.RADIO_HIDE_COMPONENTS)
    .filter((item) => item.answer === 'Yes')
    .map((item) => item.components)
    .flat();
  normalQuestionsApplicationOfDesignAndOperation =
    normalQuestionsApplicationOfDesignAndOperation.filter(
      (item) => item.type !== constantsModule.RADIO_HIDE_COMPONENTS
    );

  if (proximityRendezVousQuestions.length > 0)
    normalQuestionsApplicationOfDesignAndOperation = [
      ...normalQuestionsApplicationOfDesignAndOperation,
      ...proximityRendezVousQuestions,
    ];
  // Flattener part

  let allComplianceQuestionsApplicationOfDesignAndOperation =
    missionForm.applicationOfDesignAndOperation
      .filter((item) => !constantsModule.OTHER_TYPES.includes(item.type))
      .filter((item) => {
        return constantsModule.COMPLIANCE_QUESTIONS.includes(item.question[0]);
      });

  const otherQuestionApplicationOfDesignAndOperation = missionForm.applicationOfDesignAndOperation
    .filter(
      (item) =>
        item.question === 'Does your mission include close proximity or rendez vous operations?'
    )
    .filter((item) => item.answer === 'Yes')
    .map((item) => item.components)
    .flat();

  if (otherQuestionApplicationOfDesignAndOperation.length > 0)
    allComplianceQuestionsApplicationOfDesignAndOperation.push(
      ...otherQuestionApplicationOfDesignAndOperation
    );

  let tailor = allComplianceQuestionsApplicationOfDesignAndOperation.map((item) =>
    Number(item.answerInput)
  );

  const normalRecommendedQuestions = missionForm.applicationOfDesignAndOperation.filter(
    (item) => item.type === constantsModule.RADIO_CONDITION_VERIF
  );

  // Gets the passivation and explosion questions
  const passivationOrExplosionQuestions = normalQuestionsApplicationOfDesignAndOperation.filter(
    (item) => item.type === constantsModule.RADIO_CONDITION_INPUT_VERIF
  );
  const explosionQuestion = passivationOrExplosionQuestions.find(
    (item) =>
      item.question[0] ===
      'Level of minimization of the probability of explosion as part of the operational lifetime'
  );
  const passivationQuestions = passivationOrExplosionQuestions.filter(
    (item) =>
      item.question[0] !==
        'Level of minimization of the probability of explosion as part of the operational lifetime' &&
      !constantsModule.COMPLIANCE_QUESTIONS.includes(item.question[0])
  );

  const allScoresApplicationOfDesignAndOperationScore = [
    ...constantsModule.getComplianceQuestionsApplicationOfDesignAndOperationScore(
      allComplianceQuestionsApplicationOfDesignAndOperation,
      tailor
    ),
    ...constantsModule.getNormalQuestionsApplicationOfDesignAndOperationScore(
      normalRecommendedQuestions
    ),
    constantsModule.getExplosionScore({ question: explosionQuestion }),
    ...constantsModule.getPassivationScore({ questions: passivationQuestions }),
  ];

  const maxPoints = [
    ...allComplianceQuestionsApplicationOfDesignAndOperation,
    ...normalRecommendedQuestions,
    explosionQuestion,
    ...passivationQuestions,
  ]
    .map((item) => Math.max(...item.answerWeights))
    .reduce((a, b) => a + b, 0);

  const scoreApplicationOfDesignAndOperation =
    allScoresApplicationOfDesignAndOperationScore.reduce((a, b) => a + b, 0) / maxPoints;
  const scoreADO = Number.isNaN(scoreApplicationOfDesignAndOperation)
    ? 0
    : scoreApplicationOfDesignAndOperation;

  const sumPointsExternalServices = missionForm.externalServices
    .filter((item) => !constantsModule.OTHER_TYPES.includes(item.type))
    .map((item) => {
      const indexAnswer = item.choices.findIndex((choice) => choice === item.answer);
      return (
        item.answerWeights[indexAnswer] *
        constantsModule.verificationLevelsWeights[
          constantsModule.verificationLevels.findIndex((verif) => verif === item.verification)
        ]
      );
    });
  const maxPointsExternalServices = missionForm.externalServices
    .filter((item) => !constantsModule.OTHER_TYPES.includes(item.type))
    .map((item) => {
      if (Array.isArray(item.answer)) return item.answerWeights.reduce((a, b) => a + b, 0);
      return Math.max(...item.answerWeights);
    });

  const scoreExternalServices =
    sumPointsExternalServices
      .map((item, index) => item / maxPointsExternalServices[index])
      .reduce((a, b) => a + b, 0) / maxPointsExternalServices.length;

  const scoreES = Number.isNaN(scoreExternalServices) ? 0 : scoreExternalServices;

  const totalScore =
    0.5 * scoreMI + 0.165 * scoreCOLLA + 0.165 * scoreDS + 0.12 * scoreDIT + 0.05 * scoreADO;

  let possibleBonusApplicationOfDesignAndOperation = missionForm.applicationOfDesignAndOperation
    .filter((item) => !constantsModule.OTHER_TYPES.includes(item.type))
    .filter((item) => {
      return (
        constantsModule.COMPLIANCE_QUESTIONS.includes(item.question[0]) &&
        item.answerRadio === 'Compliant, voluntary adopted'
      );
    });

  const additionalQuestionApplicationOfDesignAndOperation =
    missionForm.applicationOfDesignAndOperation
      .filter(
        (item) =>
          item.question === 'Does your mission include close proximity or rendez vous operations?'
      )
      .filter((item) => item.answer === 'Yes')
      .map((item) => item.components)
      .flat()
      .filter((item) => item.answerRadio === 'Compliant, voluntary adopted');

  tailor = possibleBonusApplicationOfDesignAndOperation.map((item) => Number(item.answerInput));

  if (additionalQuestionApplicationOfDesignAndOperation.length > 0)
    possibleBonusApplicationOfDesignAndOperation.push(
      ...additionalQuestionApplicationOfDesignAndOperation
    );

  let bonusQuestions = [
    ...missionForm.collisionAvoidance,
    ...missionForm.dataSharing,
    ...missionForm.externalServices,
    ...missionForm.detectionIdentificationTracking,
  ].filter((item) => constantsModule.BONUS_TYPES.includes(item.type));

  const bonusScore = constantsModule.getBonusScore(
    bonusQuestions,
    possibleBonusApplicationOfDesignAndOperation,
    tailor
  );

  return {
    missionIndex: scoreMI,
    collisionAvoidance: scoreCOLLA,
    dataSharing: scoreDS,
    detectionIdentificationTracking: scoreDIT,
    applicationOfDesignAndOperation: scoreADO,
    externalServices: scoreES,
    bonus: Number.isNaN(bonusScore) ? 0 : bonusScore,
    total: Number.isNaN(totalScore) ? 0 : totalScore,
  };
};

export { computeTotalScoreModule };
