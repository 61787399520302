import { useReducer, Fragment } from 'react';
import * as endpoints from '../../utils/endpoints';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, RefreshIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import SpinnerIcon from '../components/SpinnerIcon';

const Settings = () => {
  const accessToken = localStorage.getItem('accessToken');

  const [state, updateState] = useReducer(
    (prev, next) => {
      const newState = { ...prev, ...next };
      newState.isPasswordValid = newState.newPassword.length >= 6;
      return newState;
    },
    {
      isFetching: false,
      isPasswordValid: false,
      newPassword: '',
      confirmNewPassword: '',
      showNotification: false,
      textNotification: '',
    }
  );

  const disableChangePasswordButton =
    state.newPassword !== state.confirmNewPassword || !state.isPasswordValid || state.isFetching;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const changePasswordRequest = {
      method: 'PUT',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        newPassword: state.newPassword,
        confirmNewPassword: state.confirmNewPassword,
      }),
    };
    try {
      updateState({ isFetching: true });
      const response = await fetch(
        endpoints.API + endpoints.CHANGE_PASSWORD,
        changePasswordRequest
      );
      if (!response.ok) {
        updateState({ textNotification: 'Password Change Failed', showNotification: true });
      } else {
        // const res = await response.json();
        updateState({
          newPassword: '',
          confirmNewPassword: '',
          textNotification: 'Password Changed Successfully',
          showNotification: true,
        });
      }
      setTimeout(() => {
        updateState({ textNotification: '', showNotification: false });
      }, 1500);
    } catch (err) {
      if (!err?.res) {
        updateState({ textNotification: 'No Server response' });
      } else if (err.res?.status === 400) {
        updateState({ textNotification: 'Request Error' });
      } else if (err.res?.status === 401) {
        updateState({ textNotification: 'Unauthorized' });
      } else {
        updateState({ textNotification: 'Password Change Failed' });
      }
    }
    updateState({ isFetching: false });
  };

  const Notification = () => {
    return (
      <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="fixed mt-12 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={state.showNotification}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">{state.textNotification}</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          updateState({ showNotification: false });
                        }}>
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex h-[calc(100vh-64px)]">
      <Notification />
      <div className="m-auto w-[55vw] bg-white min-h-full font-sans px-4 py-8 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900 tracking-tight">Settings</h1>
        <div className="ml-2 mt-2">
          <label className="block text-sm font-medium text-gray-700">Change password</label>
          <input
            type="password"
            placeholder="New password"
            onChange={(e) => updateState({ newPassword: e.target.value })}
            value={state.newPassword}
            className="mt-1 block w-[225px] rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"></input>
          <input
            type="password"
            placeholder="Confirm password"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onChange={(e) => updateState({ confirmNewPassword: e.target.value })}
            value={state.confirmNewPassword}
            className="mt-1 block w-[225px] rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"></input>
        </div>
        <button
          type="button"
          onClick={handleSubmit}
          disabled={disableChangePasswordButton}
          className="inline-flex items-center ml-10 mt-2 px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white disabled:cursor-not-allowed disabled:text-indigo-300 bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-800 focus:outline-non">
          <span className="pr-1">
            {state.isFetching ? (
              <SpinnerIcon />
            ) : (
              <RefreshIcon className="h-5 w-5 text-gray-200" aria-hidden="true" />
            )}
          </span>
          Change password
        </button>
      </div>
    </div>
  );
};

export default Settings;
