import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Tabs = ({ tabs, currentTab, setCurrentTab }) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab === currentTab)}>
          {React.Children.toArray(tabs.map((tab) => <option>{tab}</option>))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {React.Children.toArray(
              tabs.map((tab) => (
                <button
                  onClick={() => {
                    setCurrentTab(tab);
                  }}
                  className={classNames(
                    tab === currentTab
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab === currentTab ? 'page' : undefined}>
                  {tab}
                </button>
              ))
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
