import { NavLink } from 'react-router-dom';

import { PlusIcon } from '@heroicons/react/solid';

import useAuth from '../../../hooks/useAuth';
import { ROLES, ROUTE_CREATE_MISSION } from '../../../utils/routing/constants';
import Profile from './Profile';

const RightSide = () => {
  const { auth } = useAuth();

  return (
    <div className="absolute hidden inset-y-0 right-0 items-center pr-2 md:static md:inset-auto md:flex md:ml-6 md:pr-0">
      {auth?.email && auth?.roles.includes(ROLES.User) && (
        <NavLink title={'Add element'} to={ROUTE_CREATE_MISSION}>
          <div className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-600 hover:bg-gray-700">
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Create mission
          </div>
        </NavLink>
      )}
      <Profile />
    </div>
  );
};

export default RightSide;
