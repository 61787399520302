import BRONZE_SIMPLE from '../../../assets/certifications/01_BRONZE/bronze_simple.png';
import BRONZE_1_STAR from '../../../assets/certifications/01_BRONZE/bronze_1star.png';
import BRONZE_2_STARS from '../../../assets/certifications/01_BRONZE/bronze_2stars.png';
import BRONZE_3_STARS from '../../../assets/certifications/01_BRONZE/bronze_3stars.png';

import SILVER_SIMPLE from '../../../assets/certifications/02_SILVER/silver_simple.png';
import SILVER_1_STAR from '../../../assets/certifications/02_SILVER/silver_1star.png';
import SILVER_2_STARS from '../../../assets/certifications/02_SILVER/silver_2stars.png';
import SILVER_3_STARS from '../../../assets/certifications/02_SILVER/silver_3stars.png';

import GOLD_SIMPLE from '../../../assets/certifications/03_GOLD/gold_simple.png';
import GOLD_1_STAR from '../../../assets/certifications/03_GOLD/gold_1star.png';
import GOLD_2_STARS from '../../../assets/certifications/03_GOLD/gold_2stars.png';
import GOLD_3_STARS from '../../../assets/certifications/03_GOLD/gold_3stars.png';

import PLATINUM_SIMPLE from '../../../assets/certifications/04_PLATINUM/platinum_simple.png';
import PLATINUM_1_STAR from '../../../assets/certifications/04_PLATINUM/platinum_1star.png';
import PLATINUM_2_STARS from '../../../assets/certifications/04_PLATINUM/platinum_2stars.png';
import PLATINUM_3_STARS from '../../../assets/certifications/04_PLATINUM/platinum_3stars.png';

import NO_CERTIFICATION from '../../../assets/certifications/no_certification.png';

const getBadge = ({ tier_score, bonus_score }) => {
  const NumberBetween = (v, a, b) => v >= a && v < b;
  const Badge = () => {
    if (NumberBetween(tier_score, 0.4, 0.55)) {
      if (NumberBetween(bonus_score, 0, 0.25)) {
        return BRONZE_SIMPLE;
      } else if (NumberBetween(bonus_score, 0.25, 0.5)) {
        return BRONZE_1_STAR;
      } else if (NumberBetween(bonus_score, 0.5, 0.75)) {
        return BRONZE_2_STARS;
      }
      return BRONZE_3_STARS;
    } else if (NumberBetween(tier_score, 0.55, 0.7)) {
      if (NumberBetween(bonus_score, 0, 0.25)) {
        return SILVER_SIMPLE;
      } else if (NumberBetween(bonus_score, 0.25, 0.5)) {
        return SILVER_1_STAR;
      } else if (NumberBetween(bonus_score, 0.5, 0.75)) {
        return SILVER_2_STARS;
      }
      return SILVER_3_STARS;
    } else if (NumberBetween(tier_score, 0.7, 0.8)) {
      if (NumberBetween(bonus_score, 0, 0.25)) {
        return GOLD_SIMPLE;
      } else if (NumberBetween(bonus_score, 0.25, 0.5)) {
        return GOLD_1_STAR;
      } else if (NumberBetween(bonus_score, 0.5, 0.75)) {
        return GOLD_2_STARS;
      }
      return GOLD_3_STARS;
    } else if (tier_score >= 0.8) {
      if (NumberBetween(bonus_score, 0, 0.25)) {
        return PLATINUM_SIMPLE;
      } else if (NumberBetween(bonus_score, 0.25, 0.5)) {
        return PLATINUM_1_STAR;
      } else if (NumberBetween(bonus_score, 0.5, 0.75)) {
        return PLATINUM_2_STARS;
      }
      return PLATINUM_3_STARS;
    }
    return NO_CERTIFICATION;
  };
  return Badge();
};

export default getBadge;
