import React from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

import * as constants from '../../utils/constants';
import * as constantsRoute from '../../utils/routing/constants';

import FormTable from './components/FormTable';

export default function UserFormsTable({
  savedForms,
  loadingSavedForms,
  submittedForms,
  loadingSubmittedForms,
  ratedForms,
  loadingRatedForms,
}) {
  const { auth } = useAuth();
  // Taking only the 4 latest saved forms
  const pinnedSavedForms = [...savedForms]
    .sort((a, b) => {
      return b.date - a.date;
    })
    .filter((v, idx) => idx < 4);

  return (
    <div className="min-h-full">
      <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pb-8 flex flex-col">
        <main className="flex-1">
          {/* Pinned Forms */}
          <div className="px-4 mt-6 sm:px-6 lg:px-8">
            <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
              Latest Ongoing Forms
            </h2>
            <ul className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
              {React.Children.toArray(
                pinnedSavedForms.map((savedForm) => (
                  <li className="relative col-span-1 flex shadow-sm rounded-md">
                    <div className="bg-gray-600 uppercase flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
                      {savedForm.name.substring(0, 2)}
                    </div>
                    <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                      <div className="flex-1 px-4 py-2 text-sm truncate">
                        <Link
                          to={constantsRoute.ROUTE_USER_SAVED_FORM(auth.id, savedForm.id)}
                          className="text-gray-900 font-medium hover:text-gray-600">
                          {savedForm.name}
                        </Link>
                        <p className="text-gray-500">{constants.formatDate(savedForm.date)}</p>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>

          {/* Forms table  */}
          {!loadingSavedForms && (
            <FormTable data={savedForms} userId={auth.id} type={constants.FORM_TYPE.Saved} />
          )}
          {!loadingSubmittedForms && (
            <FormTable
              data={submittedForms}
              userId={auth.id}
              type={constants.FORM_TYPE.Submitted}
            />
          )}
          {!loadingRatedForms && (
            <FormTable data={ratedForms} userId={auth.id} type={constants.FORM_TYPE.Rated} />
          )}
        </main>
      </div>
    </div>
  );
}
