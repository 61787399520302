import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import LazyLoadImage from '../../components/LazyLoadImage';

import create_mission from '../../../assets/create_mission.png';

export default function WelcomeModal({ open, setOpen }) {
  const welcomeModal = localStorage.getItem('welcomeModal');

  useEffect(() => {
    if (!open && welcomeModal === null) localStorage.setItem('welcomeModal', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 ">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center leading-6 font-medium text-gray-900">
                    Thank you for choosing to perform the Space Sustainability Rating evaluation.
                  </Dialog.Title>
                  <div className="mt-2">
                    <div>
                      <div className="mb-2">
                        This platform allows your organisation to create your mission and enter the
                        necessary inputs for the SSR team to compute a rating.
                      </div>
                      <div>
                        On your dashboard, your missions are listed in three different categories
                      </div>
                      <ul className="list-disc pl-12">
                        <li>
                          <b>Ongoing forms </b>
                          is a list of your missions that are saved but not submitted to the SSR
                          evaluator;
                        </li>
                        <li>
                          <b>Submitted forms </b> is a list of forms submitted to the SSR evaluator;
                          and
                        </li>
                        <li>
                          <b>Rated forms </b> is a list of missions whose score has been computed by
                          the SSR evaluator
                        </li>
                      </ul>
                      <div className="my-2">
                        Please note that upon request for changes from the SSR evaluator, a
                        submitted form can be transferred to the ongoing forms with a justification
                        comment from the evaluator.
                      </div>
                      <div className="mb-2">
                        If you want to proceed, please click on the “create mission” feature to
                        start a new mission form. Once created, you can start to enter your mission
                        data.
                      </div>
                      <LazyLoadImage className="w-full" src={create_mission} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                  onClick={() => setOpen(false)}>
                  Got it !
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
