export const INPUT_VERIF = 'input-verification';
export const CHECKBOX_CONDITION_VERIF = 'checkbox-condition-verification';
export const BONUS_CHECKBOX_CONDITION_VERIF = 'bonus-checkbox-condition-verification';
export const DROPDOWN = 'dropdown';
export const RADIO_HIDE_COMPONENTS = 'radio-hide-components';
export const RADIO_CONDITION_INPUT_VERIF = 'radio-condition-input-verification';
export const RADIO_CONDITION_VERIF = 'radio-condition-verification';
export const BONUS_RADIO_CONDITION_VERIF = 'bonus-radio-condition-verification';
export const SUBJECT_TITLE = 'subject-title';
export const TITLE_GROUP = 'title-group';
export const INDICATIONS = 'indications';

export const BONUS_TYPES = [BONUS_CHECKBOX_CONDITION_VERIF, BONUS_RADIO_CONDITION_VERIF];
export const OTHER_TYPES = [SUBJECT_TITLE, INDICATIONS, TITLE_GROUP];
export const EXTRA_TYPES = [...BONUS_TYPES, ...OTHER_TYPES];

// constants to identify the type of form we have in order to display the
// correct comments/data to the user
export const DISPLAY_INPUT_COMMENTS_TO_USER = 'display-input-comments-to-user';
export const DISPLAY_INPUT_COMMENTS_AND_INPUT_ANSWERS_TO_USER =
  'display-input-comments-and-input-answers-to-user';
export const DISPLAY_USER_COMMENTS_AND_INPUT_COMMENTS_TO_ADMIN =
  'display-user-comments-and-input-comments-to-admin';
export const DISPLAY_ADMIN_COMMENTS_AND_INPUT_COMMENTS_TO_USER =
  'display-admin-comments-and-input-comments-to-user';
export const DISPLAY_ADMIN_COMMENTS_AND_USER_COMMENTS_TO_USER =
  'display-admin-comments-and-user-comments-to-user';
export const DISPLAY_NO_COMMENTS = 'display-no-comments';
export const DISPLAY_QUESTION = 'display-question';
export const DISPLAY_ANSWERS = 'display-answers';

export const COMPLIANCE_QUESTIONS = [
  'Space debris mitigation guidelines (<a href="https://www.unoosa.org/pdf/publications/st_space_49E.pdf" className="text-blue-700 underline mr-1" >UNCOPUOS </a> or <a href="https://orbitaldebris.jsc.nasa.gov/library/iadc-space-debris-guidelines-revision-2.pdf" className="text-blue-700 underline ml-1"> IADC</a>)',
  'Long-term sustainability guidelines (UNCOPUOS)',
  'Space debris mitigation standards or verifiable laws (<a href="https://www.iso.org/standard/72383.html" className="text-blue-700 underline" >ISO 24113</a> , or <a href="https://orbitaldebris.jsc.nasa.gov/library/usg_orbital_debris_mitigation_standard_practices_november_2019.pdf" className="text-blue-700 underline mx-2" >NASA ODMSP</a> or any national verifiable law)',
  '<div>Standardised operational products guidelines (CCSDS <a href="https://public.ccsds.org/Pubs/502x0b2c1e2.pdf" className="text-blue-700 underline">502.0-B-2</a> on orbital data messages or <a href="https://public.ccsds.org/Pubs/508x0b1e2c2.pdf" className="text-blue-700 underline" >508.0-B-1</a> on conjunction data messages) </div>',
  '<a href="https://www.itu.int/en/publications/ITU-R/Pages/publications.aspx?lang=en&media=electronic&parent=R-REG-RR-2020" className="text-blue-700 underline mr-1">ITU regulations</a> on spectrum use',
  'Does your mission include close proximity or rendez vous operations?',
  '<div>Safety standards in case of close proximity or rendezvous operations (CONFERS <a href="https://www.satelliteconfers.org/wp-content/uploads/2021/11/CONFERS_Operating_Practices_Revised-Oct-21.pdf" className="text-blue-700 underline">Recommended Design and Operational Practices</a> or on <a href="https://www.satelliteconfers.org/wp-content/uploads/2019/10/OOS_Mission_Phases.pdf" className="text-blue-700 underline">On-Orbit Servicing (OOS) Mission</a>) </div>',
];

export const QUALITATIVE_SCORE_QUESTION =
  'Do you track the resident space objects you operate? Select all that apply. Resident space object is tracked:';

export const verificationLevels = [
  'N/A',
  'Assertion',
  'Assertion+Documentation',
  'Public Release',
  'Authority',
];
export const verificationLevelsWeights = [1, 0.5, 0.6, 0.8, 1];

export const getScoreMissionIndex = ({
  indexAbsolute,
  indexRelative,
  deploymentDuration,
  meanVerifications,
}) => {
  const NORMALIZATION = 0.017;
  const WEIGHT_ABS = 0.8;
  const WEIGHT_REL = 0.2;

  const undnv = indexAbsolute / (NORMALIZATION * deploymentDuration * meanVerifications);
  let iferror = 0;
  try {
    iferror = Math.log10(undnv);
  } catch (err) {
    iferror = -10;
  }
  const indexAbsoluteResult = Math.min(0.5 - 0.1 * iferror - (undnv - 1) / 50, 1);
  const indexRelativeResult = meanVerifications * (1 - Math.pow(indexRelative, 3));

  let result = [0, 0, 0];

  result[0] = indexAbsoluteResult;
  result[1] = indexRelativeResult;

  result[2] = WEIGHT_ABS * indexAbsoluteResult + WEIGHT_REL * indexRelativeResult;

  return result;
};

export const getScoreCollisionAvoidance = (module) => {
  const sumPoints = module
    .map((item) => {
      return item.answer
        .map((answer, index) => {
          if (answer) {
            return (
              item.answerWeights[index] *
              verificationLevelsWeights[
                verificationLevels.findIndex((verif) => verif === item.verification)
              ]
            );
          }
          return 0;
        })
        .reduce((a, b) => a + b, 0);
    })
    .reduce((a, b) => a + b, 0);

  const maxPoints = module
    .map((item) => item.answerWeights.reduce((a, b) => a + b, 0))
    .reduce((a, b) => a + b, 0);

  return sumPoints / maxPoints;
};

export const getScoreDataSharing = (module) => {
  const sumPoints = module
    .map((item) => {
      return item.answer
        .map((answer, index) => {
          if (answer) {
            return (
              item.answerWeights[index] *
              verificationLevelsWeights[
                verificationLevels.findIndex((verif) => verif === item.verification)
              ]
            );
          }
          return 0;
        })
        .reduce((a, b) => a + b, 0);
    })
    .reduce((a, b) => a + b, 0);

  const maxPoints = module
    .map((item) => item.answerWeights.reduce((a, b) => a + b, 0))
    .reduce((a, b) => a + b, 0);

  return sumPoints / maxPoints;
};

export const getScoreDetectionIdentificationTracking = ({
  meanVerifications,
  optical,
  radar,
  passDuration,
  averageOrbitalCoverage,
  internalDuration,
  qualitativeScore,
}) => {
  let detectability = ((optical + radar) * meanVerifications) / 2;
  let trackability =
    ((passDuration + averageOrbitalCoverage + internalDuration) * meanVerifications) / 3;
  return (detectability + trackability + qualitativeScore) / 3;
};

export const getBonusScore = (module, additionalModule, tailor) => {
  const sumPoints = [
    ...module.map((item) => {
      if (Array.isArray(item.answer)) {
        return item.answer
          .map((answer, index) => {
            if (answer) {
              return (
                item.answerWeights[index] *
                verificationLevelsWeights[
                  verificationLevels.findIndex((verif) => verif === item.verification)
                ]
              );
            }
            return 0;
          })
          .reduce((a, b) => a + b, 0);
      } else {
        const indexAnswer = item.choices.findIndex((choice) => choice === item.answer);
        return (
          item.answerWeights[indexAnswer] *
          verificationLevelsWeights[
            verificationLevels.findIndex((verif) => verif === item.verification)
          ]
        );
      }
    }),
    ...getComplianceQuestionsApplicationOfDesignAndOperationScore(additionalModule, tailor),
  ];

  const maxPoints = [...module, ...additionalModule].map((item) => {
    if (Array.isArray(item.answer)) return item.answerWeights.reduce((a, b) => a + b, 0);
    return Math.max(...item.answerWeights);
  });

  return (
    sumPoints.map((item, index) => item / maxPoints[index]).reduce((a, b) => a + b, 0) /
    maxPoints.length
  );
};

const EXPLOSION_THRESHOLD = 0.001;

export const getExplosionScore = ({ question }) => {
  if (question.answerRadio === 'No action or analysis') return 0;
  if (parseFloat(question.answerInput) < EXPLOSION_THRESHOLD)
    return (
      1 *
      verificationLevelsWeights[
        verificationLevels.findIndex((verif) => verif === question.verification)
      ]
    );
  return (
    (1.0 - Math.pow(10, Math.min(0, Math.log10(parseFloat(question.answerInput)) + 2.0))) *
    verificationLevelsWeights[
      verificationLevels.findIndex((verif) => verif === question.verification)
    ]
  );
};
export const getPassivationScore = ({ questions }) => {
  return questions.map((question) => {
    const indexAnswer = question.choices.findIndex((choice) => choice === question.answerRadio);
    return (
      parseFloat(question.answerInput) *
      question.answerWeights[indexAnswer] *
      verificationLevelsWeights[
        verificationLevels.findIndex((verif) => verif === question.verification)
      ]
    );
  });
};

export const getComplianceQuestionsApplicationOfDesignAndOperationScore = (module, tailor) => {
  const pointsWhithoutTailor = module.map((item) => {
    const indexAnswer = item.choices.findIndex((choice) => choice === item.answerRadio);
    return (
      item.answerWeights[indexAnswer] *
      verificationLevelsWeights[
        verificationLevels.findIndex((verif) => verif === item.verification)
      ]
    );
  });
  return pointsWhithoutTailor.map((item, index) => item * parseFloat(tailor[index]));
};

export const getNormalQuestionsApplicationOfDesignAndOperationScore = (module) => {
  return module.map((item) => {
    const indexAnswer = item.choices.findIndex((choice) => choice === item.answer);
    return (
      item.answerWeights[indexAnswer] *
      verificationLevelsWeights[
        verificationLevels.findIndex((verif) => verif === item.verification)
      ]
    );
  });
};

export const missionIndexInitState = [
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-semibold font-md text-md"> Please provide the following information about your mission. Please note that the information contained in this section will be sent to the Space Debris office (ESA) for the computation of the mission index part of the rating. The data sent to the space debris office is protected by a non-disclosure agreement.</div>',
  },
  {
    type: TITLE_GROUP,
    title: 'Object characteristics',
  },
  {
    type: INPUT_VERIF,
    question: 'Number of satellites',
    validationID: 'VALIDATION_POSITIVE_INTEGER_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Mass (kg)',
    validationID: 'VALIDATION_POSITIVE_FLOAT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: 'Wet mass at launch',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Cross-sectional area in randomly tumbling motion (m2)',
    validationID: 'VALIDATION_POSITIVE_FLOAT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation:
      'The preferred value is the average cross section for a randomly tumbling satellite (computation can be done using ESA DRAMA - CROC tool, simulation F3, Randomly tumbling satellite) <a href="https://conference.sdo.esoc.esa.int/proceedings/sdc6/paper/23/SDC6-paper23.pdf" className="text-blue-700 underline" >methodology</a>.',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Deployment duration (years)',
    validationID: 'VALIDATION_POSITIVE_FLOAT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation:
      'Envisioned time between the first launch and the full deployment of the satellites. If your mission only includes one satellite, please indicate 1 year',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Envisioned operational lifetime (years)',
    validationID: 'VALIDATION_POSITIVE_FLOAT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation:
      'Time between launch and end of operations, does not include post mission disposal',
    userComments: '',
    adminComments: '',
  },
  {
    type: TITLE_GROUP,
    title: 'Operational orbit parameters',
  },
  {
    type: INPUT_VERIF,
    question: 'Mean operational altitude (km)',
    validationID: 'VALIDATION_MIN_ALTITUDE_100KM_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Inclination (deg)',
    validationID: 'VALIDATION_ANGLE_BETWEEN_0_AND_180_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: TITLE_GROUP,
    title: 'Disposal strategy',
  },
  {
    type: INPUT_VERIF,
    question: 'Target end-of-life apogee (km)',
    validationID: 'VALIDATION_POSITIVE_FLOAT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: 'After post-mission disposal maneuver',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Target end-of-life perigee (km)',
    validationID: 'VALIDATION_POSITIVE_FLOAT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: 'After post-mission disposal maneuver',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Expected Post-Mission Disposal success rate (%) ',
    validationID: 'VALIDATION_VALUE_BETWEEN_0_AND_100_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: TITLE_GROUP,
    title: 'Collision Avoidance strategy',
  },
  {
    type: RADIO_HIDE_COMPONENTS,
    question: 'Is your spacecraft able to perform Collision avoidance maneuvers?',
    choices: ['Yes', 'No'],
    validationID: 'VALIDATION_MANDATORY_FIELD_ANSWER',
    components: [
      {
        type: INPUT_VERIF,
        question: 'Accepted Collision Probability Level',
        validationID: 'VALIDATION_VALUE_BETWEEN_0_AND_1_VERIF',
        choicesVerification: [
          'N/A',
          'Assertion',
          'Assertion+Documentation',
          'Public Release',
          'Authority',
        ],
        answer: '',
        verification: '',
        explanation: '',
        userComments: '',
        adminComments: '',
      },
      {
        type: INPUT_VERIF,
        question: 'Lead time, or time required to perform a maneuver (days)',
        validationID: 'VALIDATION_POSITIVE_FLOAT_VERIF',
        choicesVerification: [
          'N/A',
          'Assertion',
          'Assertion+Documentation',
          'Public Release',
          'Authority',
        ],
        answer: '',
        verification: '',
        explanation: '',
        userComments: '',
        adminComments: '',
      },
    ],
    answer: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
];

export const collisionAvoidanceInitState = [
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-md text-md">This section addresses capabilities that satellite operators could have, which improve their ability to identify, respond to, and mitigate collisions. Three categories are proposed, with several items of increasing value in each category. </div>',
  },
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-semibold font-md text-md"> Entities can receive credit for any compliance below. (Meaning <a className="underline">multiple answers</a> are allowed. In that case, points are summed), please check all that apply to your mission in the following categories. </div>',
  },
  {
    type: TITLE_GROUP,
    title: 'Orbital state knowledge (during normal operations)',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question: 'The operator:',
    choices: [
      'Relies on third party public SSA providers for state information',
      'Is maintaining the orbital position state knowledge of the object',
      '<div className="my-1"> Complies with all the following: <ul> <li>• The orbital state knowledge is maintained within 10km (in any directions) </li> <li> • The orbit determination is updated in case of a maneuver or another event </li> <li>• The covariance is characterised and validated</li> </ul> </div>',
      '<div className="my-1"> Complies with all the following: <ul> <li>• The orbital state knowledge is maintained within 1km (in any directions) </li> <li> • The orbit determination is updated in case of a maneuver or another event </li> <li>• The covariance is characterised and validated</li> </ul> </div>',
    ],
    answerWeights: [0, 2, 3, 4],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation:
      'Orbital position state knowledge refers to time-indexed position and velocity information, but does not include covariance.',
    userComments: '',
    adminComments: '',
  },
  {
    type: TITLE_GROUP,
    title: 'Availability to coordinate',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question: 'The operator:',
    choices: [
      'Is not able to coordinate',
      'Is able to coordinate in response of an emergency',
      'Is able to coordinate during set hours per day',
      'Has a system routine conjunction assessment and capabilities 24/24 (human or computer) leading to a near immediate coordination and reaction in case of an urgent issue',
    ],
    answerWeights: [0, 2, 3, 4],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: TITLE_GROUP,
    title: 'Capability to coordinate',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question: 'The operator:',
    choices: [
      'Has no dedicated process for conjunction screening, assessment, or mitigation.',
      '<div className="my-1"> Comply with all the following: <ul> <li>• Has the capability to be contacted in case of close approach or emergency </li> <li> • Regularly screens obit and planned maneuvers from SSA sharing organizations and/or third-party SSA providers </li> </ul> </div>',
      '<div className="my-1"> Comply with all the following: <ul> <li>• Is capable of interpreting conjunction data messages to generate/screen mitigating maneuvers </li> <li> • Has a system for automated routine conjunction assessment </li> </ul> </div>',
      '<div className="my-1"> [High score (4 points)] Comply with all the following: <ul> <li>• Has documented procedures for collision screening, assessment, and mitigation </li> <li> • Regularly screens operational spacecraft and planned maneuvers against SSA organization catalogue </li> </ul> </div>',
    ],
    answerWeights: [0, 2, 3, 4],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: TITLE_GROUP,
    title: 'Maneuver Capability',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question: 'The spacecrafts:',
    choices: [
      'Have no maneuver capabilities',
      'Are able to maneuver, any maneuver capability is considered appropriate for this input (including differential drag)',
      'Are able to maneuver with a reaction (at least ∆v=1 cm/s) within 6 orbital revolutions',
      'Are able to maneuver with a reaction (at least ∆v=1 cm/s) within 1 orbital revolution',
    ],
    answerWeights: [0, 2, 3, 4],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: TITLE_GROUP,
    title: 'Maintaining orbital state knowledge after the end of normal operations',
  },
  {
    type: BONUS_CHECKBOX_CONDITION_VERIF,
    question: 'The operator:',
    choices: [
      'Is not maintaining the orbital state knowledge after the end of operations',
      'Is maintaining the orbital state knowledge until atmospheric reentry, or disposal to a graveyard orbit',
      'Is maintaining the orbital state knowledge within 10km (in any directions) until atmospheric reentry, or disposal to a graveyard orbit',
      'Is maintaining the orbital state knowledge within 1km (in any directions) until atmospheric reentry, or disposal to a graveyard orbit',
    ],
    answerWeights: [0, 2, 3, 4],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
];

export const dataSharingInitState = [
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-md text-md"> This section addresses the information satellite and launch vehicle operators could share with other operators and stakeholders, and the contribution of such sharing to spaceflight safety. </div>',
  },
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-md text-md"> There are four potential sharing audiences: SSA providers, operators upon request for coordination, voluntary network of operators/stakeholders, and public. For more information, see the <a href="https://spacesustainabilityrating.org/the-rating/modules-data-sharing/" className="text-blue-700 underline" > SSR Data sharing website page </a> . Operators may gain points by sharing a given type of information with a particular audience. Sharing in any category includes both publication and update commitments, i.e. operators must commit and follow through on keeping the relevant form of data up-to-date in addition to sharing it to receive credit. The specific update cadence depends on the form of data, but unless specifically specified, should be reasonable to support common operational uses of that information. </div>',
  },
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-semibold font-md text-md"> Operators can receive credit for any of the activities they do in any category of data sharing. The operator can earn points for all the actions they take, including sharing a single kind of information with multiple audiences. During the scoring process, the total number of points earned by the operator are divided by the total potential points. </div>',
  },
  {
    type: SUBJECT_TITLE,
    title: 'Collision Avoidance Coordination information',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Publish and update collision avoidance contact Information (such as name(s), title(s), phone number(s), email address(s), list of controlled objects  by NORAD ID or International Designator, languages spoken). The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 10, 10, 12, 12],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Publish and update collision avoidance contact time zone/hours of operation. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 3, 3, 3, 4],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Publish and update COLA contact/coordination response time commitments. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 1, 2, 2, 1],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: SUBJECT_TITLE,
    title: 'Satellite and Mission Information',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Publish  and update satellite ephemeris (including manoeuvres, for LEO: 7 days, MEO/GEO: 14 days into the future). Sharing archived data is encouraged, but not required. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 12, 8, 15, 15],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question: 'Publish and update covariance information. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 6, 5, 6, 6],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Publish and update covariance characterization/validation. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 1, 2, 3, 3],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Publish and update launch vehicle timing/trajectories (planned and actual). The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 3, 1, 1, 2],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: SUBJECT_TITLE,
    title: 'Satellite Characterization information',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question: 'Publish and update satellite mass. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 4, 3, 4, 4],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Publish and update satellite manoeuvrability (manoeuvrable/non-manoeuvrable). The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 5, 5, 6, 6],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Publish and update satellite manoeuvrability capability. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 3, 2, 3, 3],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation:
      'The intention of such sharing is to help other operators understand the capability of a particular spacecraft to maneuver in case of a high risk conjunction or other necessary event, especially for systems that use low-thrust propulsion or differential drag techniques.  Potential forms of data sharing that meet this objective would include maximum achievable displacement from a reference orbit in 24 hours (or another reasonable time period,  capability per revolution, or similar).  Order of magnitude rather than precise values are acceptable.',
    userComments: '',
    adminComments: '',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Publish and update satellite operational status (operational/non-operational). The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 5, 5, 6, 6],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation:
      'This refers to the real-time operational status of the satellite post-launch until disposal.',
    userComments: '',
    adminComments: '',
  },
  {
    type: SUBJECT_TITLE,
    title: 'Autonomous systems for satellite manoeuvering',
  },
  {
    type: RADIO_HIDE_COMPONENTS,
    question:
      'Is your mission using autonomous systems (systems without a human in the loop) for satellite manoeuvring?',
    choices: ['Yes', 'No'],
    validationID: 'VALIDATION_MANDATORY_FIELD_ANSWER',
    components: [
      {
        type: CHECKBOX_CONDITION_VERIF,
        question: 'The criteria for when a manoeuvre is triggered. The information is shared with:',
        choices: [
          'None of the stakeholders below',
          'SSA providers',
          'Other operators upon request',
          'Voluntary network of operations / stakeholders',
          'Public',
        ],
        answerWeights: [0, 5, 3, 5, 5],
        validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
        answer: [false, false, false, false, false],
        conditionValuesOnAnswer: {
          verification: [
            ['N/A'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
          ],
        },
        choicesVerification: [
          'N/A',
          'Assertion',
          'Assertion+Documentation',
          'Public Release',
          'Authority',
        ],
        verification: '',
        explanation: '',
        userComments: '',
        adminComments: '',
      },
      {
        type: CHECKBOX_CONDITION_VERIF,
        question:
          'Where and with what frequency planned autonomous manoeuvres are reflected in shared SSA information. The information is shared with:',
        choices: [
          'None of the stakeholders below',
          'SSA providers',
          'Other operators upon request',
          'Voluntary network of operations / stakeholders',
          'Public',
        ],
        answerWeights: [0, 5, 3, 5, 5],
        validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
        answer: [false, false, false, false, false],
        conditionValuesOnAnswer: {
          verification: [
            ['N/A'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
          ],
        },
        choicesVerification: [
          'N/A',
          'Assertion',
          'Assertion+Documentation',
          'Public Release',
          'Authority',
        ],
        verification: '',
        explanation: '',
        userComments: '',
        adminComments: '',
      },
      {
        type: CHECKBOX_CONDITION_VERIF,
        question:
          'If emergency stop procedures exist to interrupt autonomous procedures in case of malfunction and how another operator should request an emergency stop. The information is shared with:',
        choices: [
          'None of the stakeholders below',
          'SSA providers',
          'Other operators upon request',
          'Voluntary network of operations / stakeholders',
          'Public',
        ],
        answerWeights: [0, 2, 2, 3, 3],
        validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
        answer: [false, false, false, false, false],
        conditionValuesOnAnswer: {
          verification: [
            ['N/A'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
          ],
        },
        choicesVerification: [
          'N/A',
          'Assertion',
          'Assertion+Documentation',
          'Public Release',
          'Authority',
        ],
        verification: '',
        explanation: '',
        userComments: '',
        adminComments: '',
      },
    ],
    answer: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: SUBJECT_TITLE,
    title: 'Other forms of data sharing (BONUS)',
  },
  {
    type: BONUS_CHECKBOX_CONDITION_VERIF,
    question:
      'Radio-frequency information to support interference avoidance/mitigation/geolocation. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 1, 4, 3, 3],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: BONUS_CHECKBOX_CONDITION_VERIF,
    question: 'Spacecraft anomaly information. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 1, 2, 3, 4],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: BONUS_CHECKBOX_CONDITION_VERIF,
    question:
      'Other datasets to support government/academic research. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 3, 3, 3, 4],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation:
      'Examples might include (but are not limited to) non-lethal impact data, inferred atmospheric drag, and/or manoeuvre information.',
    userComments: '',
    adminComments: '',
  },
  {
    type: BONUS_CHECKBOX_CONDITION_VERIF,
    question:
      'APIs or other means for automatic machine to machine access to above information. The information is shared with:',
    choices: [
      'None of the stakeholders below',
      'SSA providers',
      'Other operators upon request',
      'Voluntary network of operations / stakeholders',
      'Public',
    ],
    answerWeights: [0, 1, 1, 2, 2],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation:
      'Points are per totally compliant data category (Collision Avoidance Coordination Information, Satellite Metric Information, Satellite Characterisation Information), so total score for automatic access is three times these values.',
    userComments: '',
    adminComments: '',
  },
];

export const detectionIdentificationTrackingInitState = [
  {
    type: INDICATIONS,
    title:
      'Small objects which might be operational but cannot be reliably included in space surveillance and tracking products form a risk to other objects in the space environment. Moreover, identification is required for registration and liability purposes. This module aims to cover these aspects. As space surveillance and tracking capabilities improve and become more accurate in tracking satellites, this module is expected to undergo updates with each SSR version. This module is based on a simulation and computed by the Space Enabled Research Group at MIT and the University of Texas at Austin.',
  },
  {
    type: INDICATIONS,
    title:
      'Please provide to the SSR evaluator the following characteristic and planned orbital information for your satellite:',
  },
  {
    type: SUBJECT_TITLE,
    title: 'External geometry',
  },
  {
    type: DROPDOWN,
    question: 'Geometric Approximation and Dimensions',
    choices: ['Rectangular prism', 'Cylinder', 'Sphere'],
    emptyOption: 'Select a shape',
    validationID: 'VALIDATION_MANDATORY_FIELD_ANSWER',
    answer: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question:
      'Dimension approximation (please enter the dimensions for each type of satellite in the mission)',
    validationID: 'VALIDATION_MANDATORY_FIELD_ANSWER',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: SUBJECT_TITLE,
    title: 'Orbital information',
  },
  {
    type: INDICATIONS,
    title: 'Operational Orbit Parameters',
  },
  {
    type: INPUT_VERIF,
    question: 'Operational apogee altitude: z_a km',
    validationID: 'VALIDATION_POSITIVE_FLOAT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Operational perigee altitude: z_p km',
    validationID: 'VALIDATION_POSITIVE_FLOAT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Inclination: i °',
    validationID: 'VALIDATION_ANGLE_BETWEEN_0_AND_180_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Right Ascension of the Ascending node (RAAN): Ω°',
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation:
      'If not relevant, indicate 0. If the satellite drifts, indicate this parameter at the date of deployment.',
    userComments: '',
    adminComments: '',
  },
  {
    type: INPUT_VERIF,
    question: 'Argument of perigee: ω°',
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation:
      'If not relevant, indicate 0. If the satellite drifts, indicate this parameter at the date of deployment.',
    userComments: '',
    adminComments: '',
  },
  {
    type: SUBJECT_TITLE,
    title: 'Detectability, Identification and Tracking qualitative score',
  },
  {
    type: INDICATIONS,
    title:
      'Entities can receive credit for any item where they satisfy. If an operator is able to fulfil multiple items in a single question, those point values are added. During the scoring process, the total number of points earned by the entity are divided by the total possible points.',
  },
  {
    type: CHECKBOX_CONDITION_VERIF,
    question:
      'Do you track the resident space objects you operate? Select all that apply. Resident space object is tracked:',
    choices: [
      'Resident Space Object is not tracked',
      'Rely on Space-track or other third party public SSA providers',
      'Custody of operated satellites maintained within 14 days of deployment and thereafter',
      'Custody of operated satellites maintained within 1 day of deployment and thereafter',
    ],
    answerWeights: [0, 1, 2, 3],
    validationID: 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF',
    answer: [false, false, false, false],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: SUBJECT_TITLE,
    title:
      'Bonus: Provide verifiable photometric/radiometric characterisation data on the satellite to the SSR evaluator',
  },
  {
    type: BONUS_RADIO_CONDITION_VERIF,
    question:
      'Have you provided the following characterisation data on the satellite to the SSR evaluator? Radiometric Data (average/max/min Radar Cross Section)',
    choices: ['Yes', 'No'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A'],
      ],
    },
    answerWeights: [1, 0],
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: BONUS_RADIO_CONDITION_VERIF,
    question: 'Photometric Data (average/max/min Visual Magnitude)',
    choices: ['Yes', 'No'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A'],
      ],
    },
    answerWeights: [1, 0],
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
];

export const applicationOfDesignAndOperationInitState = [
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-md text-md"> The adoption of internationally endorsed standards in the space domain has been identified as a clear path towards ensuring compatibility in understanding between operators among themselves, and between an operator and the space environment which is being used. It is however recognised that design and operation standards can have regional differences while trying to achieve the goal of extended space sustainability. For the SSR module, the following scoring approach is proposed to gauge the level of commitment, by means of a set of questionnaire entries that go hand in hand with the general validation strategy. </div>',
  },
  {
    type: SUBJECT_TITLE,
    title: 'National and international guidelines',
  },
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-semibold font-md text-md"> Entities can receive credit for the criteria they satisfy. </div>',
  },
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-semibold font-md text-md"> Please check if you comply with the following guidelines, and if yes, if you have adopted it mandatorily (+1 tier point) or voluntarily (+1 tier point and +1 bonus point). Please note that in case the standard is mandatorily adopted, the total number of bonus points is decreased in order not to penalise operators launching in a rather highly regulated regime. On the contrary, operators launching from a regime with fewer mandatory guidelines are rewarded with bonus points if they voluntarily comply with the guidelines and standards. </div>',
  },
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-semibold font-md text-md"> Please note that the compliance to each set of guidelines is not treated as binary (Yes/No) but rather uses a tailoring coefficient per set of guidelines. The applicant is hence expected to provide details on the comment section regarding which item of the guidelines is strictly followed, and specify any non compliance to the set of guidelines.  This will allow the SSR issuer to assess if the standards have been tailored to remove or loosen some requirements. The SSR issuer will then issue a tailoring percentage based on the level of compliance within a given set of guidelines. This percentage will apply a weight to the earned points (80% compliance will result in 0.8 point earned for an input).</div>',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: [
      'Space debris mitigation guidelines (<a href="https://www.unoosa.org/pdf/publications/st_space_49E.pdf" className="text-blue-700 underline mr-1" >UNCOPUOS </a> or <a href="https://orbitaldebris.jsc.nasa.gov/library/iadc-space-debris-guidelines-revision-2.pdf" className="text-blue-700 underline ml-1"> IADC</a>)',
      'Tailor',
    ],
    choices: ['No compliance', 'Compliant, mandatory adopted', 'Compliant, voluntary adopted'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [0, 0, 0],
    },
    answerWeights: [0, 1, 1],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: INDICATIONS,
    title:
      '<div className="font-md text-md"> The <a href="https://www.unoosa.org/res/oosadoc/data/documents/2018/aac_1052018crp/aac_1052018crp_20_0_html/AC105_2018_CRP20E.pdf" className="text-blue-700 underline"> UNCOPUOS Long-Term Sustainability Guidelines</a> are mostly relevant and applicable to states and intergovernmental organisations. There are however criteria that are directly applicable or can be interpreted as applicable to satellite operators. The considered criteria are listed in the UN_LTS_Reference document sent by the issuer. </div>',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: ['Long-term sustainability guidelines (UNCOPUOS)', 'Tailor'],
    choices: ['No compliance', 'Compliant, mandatory adopted', 'Compliant, voluntary adopted'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [0, 0, 0],
    },
    answerWeights: [0, 1, 1],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: INDICATIONS,
    title:
      'Please specify in the comment section what standards or verifiable law you are complying with.',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: [
      'Space debris mitigation standards or verifiable laws (<a href="https://www.iso.org/standard/72383.html" className="text-blue-700 underline" >ISO 24113</a> , or <a href="https://orbitaldebris.jsc.nasa.gov/library/usg_orbital_debris_mitigation_standard_practices_november_2019.pdf" className="text-blue-700 underline mx-2" >NASA ODMSP</a> or any national verifiable law)',
      'Tailor',
    ],
    choices: ['No compliance', 'Compliant, mandatory adopted', 'Compliant, voluntary adopted'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [0, 0, 0],
    },
    answerWeights: [0, 1, 1],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: [
      '<div>Standardised operational products guidelines (CCSDS <a href="https://public.ccsds.org/Pubs/502x0b2c1e2.pdf" className="text-blue-700 underline">502.0-B-2</a> on orbital data messages or <a href="https://public.ccsds.org/Pubs/508x0b1e2c2.pdf" className="text-blue-700 underline" >508.0-B-1</a> on conjunction data messages) </div>',
      'Tailor',
    ],
    choices: ['No compliance', 'Compliant, mandatory adopted', 'Compliant, voluntary adopted'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [0, 0, 0],
    },
    answerWeights: [0, 1, 1],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: [
      '<a href="https://www.itu.int/en/publications/ITU-R/Pages/publications.aspx?lang=en&media=electronic&parent=R-REG-RR-2020" className="text-blue-700 underline mr-1">ITU regulations</a> on spectrum use',
      'Tailor',
    ],
    choices: ['No compliance', 'Compliant, mandatory adopted', 'Compliant, voluntary adopted'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [0, 0, 0],
    },
    answerWeights: [0, 1, 1],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: RADIO_HIDE_COMPONENTS,
    question: 'Does your mission include close proximity or rendez vous operations?',
    choices: ['Yes', 'No'],
    validationID: 'VALIDATION_MANDATORY_FIELD_ANSWER',
    components: [
      {
        type: RADIO_CONDITION_INPUT_VERIF,
        question: [
          '<div>Safety standards in case of close proximity or rendezvous operations (CONFERS <a href="https://www.satelliteconfers.org/wp-content/uploads/2021/11/CONFERS_Operating_Practices_Revised-Oct-21.pdf" className="text-blue-700 underline">Recommended Design and Operational Practices</a> or on <a href="https://www.satelliteconfers.org/wp-content/uploads/2019/10/OOS_Mission_Phases.pdf" className="text-blue-700 underline">On-Orbit Servicing (OOS) Mission</a>) </div>',
          'Tailor',
        ],
        choices: ['No compliance', 'Compliant, mandatory adopted', 'Compliant, voluntary adopted'],
        conditionValuesOnAnswer: {
          verification: [
            ['N/A'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
            ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
          ],
          answerInput: [0, 0, 0],
        },
        answerWeights: [0, 1, 1],
        validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
        choicesVerification: [
          'N/A',
          'Assertion',
          'Assertion+Documentation',
          'Public Release',
          'Authority',
        ],
        answerRadio: '',
        answerInput: '',
        verification: '',
        explanation: '',
        userComments: '',
        adminComments: '',
      },
    ],
    answer: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: SUBJECT_TITLE,
    title: 'Other recommended best-practises',
  },
  {
    type: RADIO_CONDITION_VERIF,
    question: 'Does your spacecraft release debris in orbit as part of the operations?',
    choices: ['Yes', ' No, or only smaller than 1mm'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    answerWeights: [0, 1],
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: RADIO_CONDITION_VERIF,
    question: 'Is your payload registered by your launching state?',
    choices: ['No', 'Yes'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    answerWeights: [0, 1],
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: [
      'Level of minimization of the probability of explosion as part of the operational lifetime',
      'X explosion satellite',
    ],
    choices: ['No action or analysis', 'Yes, please enter a value of explosion probability'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [1, 0],
    },
    answerWeights: [0, 1],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: [
      'Spacecraft passivated after its operational lifetime',
      'Please provide the passivation success ratio of your satellite (value between 0 and 1). In case of constellations, please provide a mean value.',
    ],
    choices: ['No', 'Yes', 'Yes, with a near-controlled reentry'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [0, 0, 0],
    },
    answerWeights: [0, 1, 2],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: [
      'Launch vehicle passivated after its operational lifetime',
      'Please provide the passivation success ratio of your launcher (value between 0 and 1). In case of constellations, please provide a mean value.',
    ],
    choices: ['No', 'Yes', 'Yes, with a near-controlled reentry'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [0, 0, 0],
    },
    answerWeights: [0, 1, 2],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: [
      'The spacecraft uses a disposal orbit after its operational lifetime',
      'Please provide the PMD success ratio of your satellite (value between 0 and 1). In case of constellations, please provide a mean value.',
    ],
    choices: ['No', 'Yes', 'Yes, with a near-controlled reentry'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [0, 0, 0],
    },
    answerWeights: [0, 1, 2],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
  {
    type: RADIO_CONDITION_INPUT_VERIF,
    question: [
      'The launch vehicle uses a disposal orbit after its operational lifetime',
      'Please provide the PMD success ratio of your launcer (value between 0 and 1). In case of constellations, please provide a mean value.',
    ],
    choices: ['No', 'Yes', 'Yes, with a near-controlled reentry'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
      answerInput: [0, 0, 0],
    },
    answerWeights: [0, 1, 2],
    validationID: 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answerRadio: '',
    answerInput: '',
    verification: '',
    explanation: '',
    userComments: '',
    adminComments: '',
  },
];

export const externalServicesInitState = [
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-md text-md"> External features consider a wide range of activities and identifies classes of action that satellite operators can take to make their mission more amenable to receive External Services (ES) or to increase the probability of successful external services such as fixing, improving, and reviving satellites and refers to any work to refuel, repair, replace, or augment a satellite in space. </div>',
  },
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-md text-md"> They also consider design features that ensure or improve the detectability, trackability and identifiability of satellites in non-cooperative situations such as a low-power beacon or reflector. On-orbit servicing of satellites takes into consideration life-extension and upgradability as technology evolves, as well as the employment of external end-of-life disposal strategy. </div>',
  },
  {
    type: INDICATIONS,
    title:
      '<div className="italic font-md text-md"> The SSR does not assume that all operators will invest in External Services, and in some cases such as low altitude orbits or small satellites, external services are not deemed necessary. As such, <a className="font-semibold "> the External Services module aims at providing bonus rating </a> for missions where the investment in external services capabilities are appropriate. The <a className="font-semibold "> tier score of the applicant is not impacted </a> if ES are not used. </div>',
  },
  {
    type: INDICATIONS,
    title: 'Entities can receive credit for the criteria they satisfy.',
  },
  {
    type: BONUS_RADIO_CONDITION_VERIF,
    question: 'On-orbit servicing features ?',
    choices: ['No', 'Yes'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    answerWeights: [0, 1],
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation:
      'Example: Installing of OOS features in preparation to create a fail-safe option. Include visual fiducials, grapple fixtures, mechanical features, grasp features, and items to make it easier to track the object in case of radio failures such as beacons',
    userComments: '',
    adminComments: '',
  },
  {
    type: BONUS_RADIO_CONDITION_VERIF,
    question: 'Standardized interfaces?',
    choices: ['No', 'Yes'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    answerWeights: [0, 1],
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: 'Employment of OOS capabilities that include standardised interfaces',
    userComments: '',
    adminComments: '',
  },
  {
    type: BONUS_RADIO_CONDITION_VERIF,
    question:
      'Life extension services (non-contact support,inspection, refuelling, upgradability, orbit modification and maintenance) ?',
    choices: ['No', 'Yes'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    answerWeights: [0, 1],
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation: 'Commitment to use or demonstration of use of On Orbit Servicing',
    userComments: '',
    adminComments: '',
  },
  {
    type: BONUS_RADIO_CONDITION_VERIF,
    question: 'External Active debris removal services ?',
    choices: ['No', 'Yes'],
    conditionValuesOnAnswer: {
      verification: [
        ['N/A'],
        ['N/A', 'Assertion', 'Assertion+Documentation', 'Public Release', 'Authority'],
      ],
    },
    answerWeights: [0, 1],
    validationID: 'VALIDATION_MANDATORY_FIELD_VERIF',
    choicesVerification: [
      'N/A',
      'Assertion',
      'Assertion+Documentation',
      'Public Release',
      'Authority',
    ],
    answer: '',
    verification: '',
    explanation:
      'Use of External Active Debris removal, but still compliance with the 25 years deorbit rule',
    userComments: '',
    adminComments: '',
  },
];

export const missionForminitState = {
  missionIndex: missionIndexInitState,
  collisionAvoidance: collisionAvoidanceInitState,
  dataSharing: dataSharingInitState,
  detectionIdentificationTracking: detectionIdentificationTrackingInitState,
  applicationOfDesignAndOperation: applicationOfDesignAndOperationInitState,
  externalServices: externalServicesInitState,
};
