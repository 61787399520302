import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useFetchCall from '../../api/useFetchCall';
import * as endpoints from '../../utils/endpoints';
import Loading from '../Loading';
import MissionForm from './components/MissionForm';

import * as constantsModule from '../../utils/modules/constants';
import * as constantsRoute from '../../utils/routing/constants';
import { tabsTitles } from '../../utils/texts';
// import Breadcrumbs from '../components/Breadcrumbs';
import getBadge from './components/getBadge';
import ModuleWebChart from './components/ModuleWebChart';
import ModuleHistogramCumulativeScore from './components/ModuleHistogramCumulativeScore';
import LazyLoadImage from '../components/LazyLoadImage';

const mainTabs = [{ name: 'Rating' }, { name: 'Form' }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const RatedForm = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');

  const [form, setForm] = useState({});
  const [score, setScore] = useState({
    missionIndex: 0,
    collisionAvoidance: 0,
    dataSharing: 0,
    detectionIdentificationTracking: 0,
    applicationOfDesignAndOperation: 0,
    bonus: 0,
    total: 0,
  });

  const [mainTab, setMainTab] = useState(mainTabs[0]);
  const [BadgeImg, setBadgeImg] = useState(null);

  const [currentTab, setCurrentTab] = useState(tabsTitles[0]);
  const [missionForm, setMissionForm] = useState(constantsModule.missionForminitState);

  const getUserRatedFormRequest = {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  };

  const [data, loading, error] = useFetchCall(
    endpoints.USER_RATED_FORM(formId),
    getUserRatedFormRequest
  );

  useEffect(() => {
    return () => {
      setCurrentTab(tabsTitles[0]);
      setMissionForm(constantsModule.missionForminitState);
    };
  }, []);

  useEffect(() => {
    if (error) {
      if (error.success === false) {
        if (error.message === 'Rated form not found') {
          navigate(constantsRoute.ROUTE_NOT_FOUND);
        } else {
          navigate(constantsRoute.ROUTE_HOME);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.data) {
      setForm(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (form?.data) {
      setMissionForm(form.data);
      setScore(form.ratedData);
    }
  }, [form]);

  useEffect(() => {
    if (score.total >= 0) {
      setBadgeImg(
        getBadge({
          tier_score: score.total,
          bonus_score: score.bonus,
        })
      );
    }
  }, [score]);

  const percentStrToPercentNum = (string) => {
    return Number((string * 100).toFixed(2));
  };

  const SwitchMainTabs = () => {
    switch (mainTab.name) {
      case 'Rating':
        return (
          <>
            <div className="flex items-center justify-center pb-2">
              <div className="flex items-center justify-center first-letter:bg-gray-200 shadow rounded-lg w-96">
                <LazyLoadImage className="h-[200px]" src={BadgeImg} alt="badge" />
              </div>
            </div>
            <dl className="my-5 grid">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">Comments</dt>
                <dd className="mt-1 text-xl font-medium text-gray-900">{form.adminComments}</dd>
              </div>
            </dl>
            <dl className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">Tier Score</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {percentStrToPercentNum(score.total)} %
                </dd>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">Bonus Score</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {percentStrToPercentNum(score.bonus)} %
                </dd>
              </div>
            </dl>

            <dl className="mt-5 mb-1 grid grid-cols-1 rounded-t-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">Mission Index</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.missionIndex)} %
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">
                  Collision Avoidance Capabilities{' '}
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.collisionAvoidance)} %
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">Data Sharing</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.dataSharing)} %
                  </div>
                </dd>
              </div>
            </dl>

            <dl className="mb-5 grid grid-cols-1 rounded-b-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">
                  Detection, Identification and Tracking
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.detectionIdentificationTracking)} %
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-sm text-gray-900">
                  Application of Design and Operation Standards
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.applicationOfDesignAndOperation)} %
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-normal text-gray-900">External Services</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-500">
                    {percentStrToPercentNum(score.externalServices)} %
                  </div>
                </dd>
              </div>
            </dl>
            <div className="grid grid-cols-2 pb-8">
              <ModuleWebChart results={[{ name: form.name, score }]} />
              <ModuleHistogramCumulativeScore results={[{ name: form.name, score }]} />
            </div>
          </>
        );
      case 'Form':
        return MissionForm({
          currentTab,
          setCurrentTab,
          tabsTitles,
          missionForm,
          setMissionForm,
          displayType: constantsModule.DISPLAY_ADMIN_COMMENTS_AND_USER_COMMENTS_TO_USER,
        });
      default:
        break;
    }
  };

  const DisplayTabs = () => {
    return (
      <nav className="-mb-px flex space-x-8">
        {React.Children.toArray(
          mainTabs.map((tab) => (
            <div
              onClick={() => setMainTab(tab)}
              className={classNames(
                mainTab.name === tab.name
                  ? 'border-red-500 text-red-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'cursor-pointer whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
              )}>
              {tab.name}
            </div>
          ))
        )}
      </nav>
    );
  };

  return !loading ? (
    <>
      <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pl-4 pt-6 ">
        <div className="border-b border-gray-200 mb-2">
          <div className="sm:flex sm:items-baseline ">
            <h3 className="text-lg leading-6 pl-4 font-medium text-gray-900">{form?.name}</h3>
            <div className="mt-4 sm:mt-0 sm:ml-10">{DisplayTabs()}</div>
          </div>
        </div>
        {SwitchMainTabs()}
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default RatedForm;
