import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import QuestionExplanation from '../QuestionExplanation';
import { handleValidation } from '../utils/utils';
import * as constantsModule from '../../../../utils/modules/constants';

const CheckboxConditionVerif = ({ question, handleChange, displayType }) => {
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    handleValidation({ question, setErrors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const displayQuestion = () => {
    return (
      <>
        <label className="flex font-medium">
          {parse(question.question)}
          {question.explanation !== '' && (
            <QuestionExplanation explanation={question.explanation} />
          )}
        </label>
        <div className="pl-2 text-xs text-red-600">
          {React.Children.toArray(errors.map((error, index) => <div>{error}</div>))}
        </div>
      </>
    );
  };

  const onlyOneElementSelected = Array.isArray(question.answer)
    ? question.answer.filter((item) => item === true).length === 1
    : false;
  const indexElementSelected = Array.isArray(question.answer)
    ? question.answer.findIndex((item) => item === true)
    : 0;
  const disableVerificationDropdown =
    onlyOneElementSelected &&
    question.conditionValuesOnAnswer.verification[indexElementSelected]?.length === 1;

  const switchQuestionDisplayType = () => {
    switch (displayType) {
      case constantsModule.DISPLAY_INPUT_COMMENTS_TO_USER:
        return (
          <>
            {displayQuestion()}
            {React.Children.toArray(
              question.choices.map((choice, index) => (
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="question"
                    disabled={disableVerificationDropdown && !question.answer[index]}
                    checked={question.answer[index] === true}
                    value={choice}
                    onChange={handleChange('answer')}
                    className="mr-2 accent-gray-600 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <span className="text-sm">{parse(choice)}</span>
                </label>
              ))
            )}
            <select
              name="question"
              disabled={disableVerificationDropdown}
              value={question.verification}
              onChange={handleChange('verification')}
              className="mt-1 mr-2 w-30 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md">
              <option value="" label="Select a verification level" />
              {React.Children.toArray(
                question.choicesVerification.map((choice) => (
                  <option value={choice}>{choice}</option>
                ))
              )}
            </select>
            <input
              type="text"
              name="question"
              placeholder="(Optional) Your comments"
              value={question.userComments}
              onChange={handleChange('userComments')}
              className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </>
        );
      case constantsModule.DISPLAY_INPUT_COMMENTS_AND_INPUT_ANSWERS_TO_USER:
        return (
          <>
            {displayQuestion()}
            <div className="flex flex-col">
              <div className="ml-3">
                <label className="mt-1">Answer(s): </label>
                <div className="flex flex-col">
                  {React.Children.toArray(
                    question.answer.map((item, index) => {
                      if (item) {
                        return <div className="ml-2">{parse(question.choices[index])}</div>;
                      }
                      return undefined;
                    })
                  )}
                </div>
              </div>
            </div>
            <div>
              {question?.userComments !== '' && question?.userComments && (
                <div className="w-2/3 ml-3">
                  <label className="mt-1">Your comments: {question.userComments} </label>
                </div>
              )}
              {question?.adminComments !== '' && question?.adminComments && (
                <div className="w-2/3 ml-3">
                  <label className="mt-1 font-semibold text-lg">
                    Admin comments: {question.adminComments}{' '}
                  </label>
                </div>
              )}
            </div>
          </>
        );
      case constantsModule.DISPLAY_USER_COMMENTS_AND_INPUT_COMMENTS_TO_ADMIN:
        return (
          <>
            {displayQuestion()}
            {React.Children.toArray(
              question.choices.map((choice, index) => (
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="question"
                    disabled={disableVerificationDropdown && !question.answer[index]}
                    checked={question.answer[index] === true}
                    value={choice}
                    onChange={handleChange('answer')}
                    className="mr-2 accent-gray-600 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <span className="text-sm">{parse(choice)}</span>
                </label>
              ))
            )}
            <select
              name="question"
              disabled={disableVerificationDropdown}
              value={question.verification}
              onChange={handleChange('verification')}
              className="mt-1 mr-2 w-30 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md">
              <option value="" label="Select a verification level" />
              {React.Children.toArray(
                question.choicesVerification.map((choice) => (
                  <option value={choice}>{choice}</option>
                ))
              )}
            </select>
            <div className="flex">
              {question?.userComments !== '' && question?.userComments && (
                <div className="max-w-[33%] mt-1 mx-3">
                  <label className="mt-1">User comments:{question.userComments} </label>
                </div>
              )}
              <input
                type="text"
                name="question"
                placeholder="(Admin) Your comments"
                value={question.adminComments}
                onChange={handleChange('adminComments')}
                className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </>
        );
      case constantsModule.DISPLAY_ADMIN_COMMENTS_AND_INPUT_COMMENTS_TO_USER:
        return (
          <>
            {displayQuestion()}
            <>
              {React.Children.toArray(
                question.choices.map((choice, index) => (
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="question"
                      disabled={disableVerificationDropdown && !question.answer[index]}
                      checked={question.answer[index] === true}
                      value={choice}
                      onChange={handleChange('answer')}
                      className="mr-2 accent-gray-600 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <span className="text-sm">{parse(choice)}</span>
                  </label>
                ))
              )}
              <select
                name="question"
                disabled={disableVerificationDropdown}
                value={question.verification}
                onChange={handleChange('verification')}
                className="mt-1 mr-2 w-30 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md">
                <option value="" label="Select a verification level" />
                {React.Children.toArray(
                  question.choicesVerification.map((choice) => (
                    <option value={choice}>{choice}</option>
                  ))
                )}
              </select>
              <input
                type="text"
                name="question"
                placeholder="(Optional) Your comments"
                value={question.userComments}
                onChange={handleChange('userComments')}
                className="mt-1 mr-2 focus:ring-gray-600 focus:border-gray-600 block w-60 shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </>
            {question?.adminComments !== '' && question?.adminComments && (
              <div className="w-2/3 ml-3">
                <label className="mt-1 font-semibold text-lg">
                  Admin comments: {question.adminComments}{' '}
                </label>
              </div>
            )}
          </>
        );
      case constantsModule.DISPLAY_ADMIN_COMMENTS_AND_USER_COMMENTS_TO_USER:
        return (
          <>
            {displayQuestion()}
            <div className="flex flex-col">
              <div className="ml-3">
                <label className="mt-1">Answer(s): </label>
                <div className="flex flex-col">
                  {React.Children.toArray(
                    question.answer.map((item, index) => {
                      if (item) {
                        return <div className="ml-2">{parse(question.choices[index])}</div>;
                      }
                      return undefined;
                    })
                  )}
                </div>
              </div>
              <div className="ml-3">
                <label className="mt-1">Verification Level: {question.verification}</label>
              </div>
              {question?.userComments !== '' && question?.userComments && (
                <div className="w-2/3 ml-3">
                  <label className="mt-1">Your comments: {question.userComments} </label>
                </div>
              )}
              {question?.adminComments !== '' && question?.adminComments && (
                <div className="w-2/3 ml-3">
                  <label className="mt-1 font-semibold text-lg">
                    Admin comments: {question.adminComments}{' '}
                  </label>
                </div>
              )}
            </div>
          </>
        );
      case constantsModule.DISPLAY_NO_COMMENTS:
        return (
          <>
            {displayQuestion()}
            {React.Children.toArray(
              question.choices.map((choice, index) => (
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="question"
                    disabled={disableVerificationDropdown && !question.answer[index]}
                    checked={question.answer[index] === true}
                    value={choice}
                    onChange={handleChange('answer')}
                    className="mr-2 accent-gray-600 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <span className="text-sm">{parse(choice)}</span>
                </label>
              ))
            )}
            <select
              name="question"
              disabled={disableVerificationDropdown}
              value={question.verification}
              onChange={handleChange('verification')}
              className="mt-1 mr-2 w-30 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md">
              <option value="" label="Select a verification level" />
              {React.Children.toArray(
                question.choicesVerification.map((choice) => (
                  <option value={choice}>{choice}</option>
                ))
              )}
            </select>
          </>
        );
      case constantsModule.DISPLAY_QUESTION:
        return <>{displayQuestion()}</>;
      case constantsModule.DISPLAY_ANSWERS:
        return (
          <>
            <div className="flex flex-col">
              {React.Children.toArray(
                question.answer.map((item, index) => {
                  if (item) {
                    return <div>{parse(question.choices[index])}</div>;
                  }
                  return undefined;
                })
              )}
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return switchQuestionDisplayType();
};

export default CheckboxConditionVerif;
