import { CheckIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useFetchCall from '../../api/useFetchCall';
import * as endpoints from '../../utils/endpoints';
import SpinnerIcon from '../components/SpinnerIcon';
import Loading from '../Loading';
import MissionForm from './components/MissionForm';

import * as constantsModule from '../../utils/modules/constants';
import * as constantsRoute from '../../utils/routing/constants';
import { tabsTitles } from '../../utils/texts';
import useAuth from '../../hooks/useAuth';
import useValidateMissionForm from './question/schema/useValidateMissionForm';
import { SaveIcon } from '@heroicons/react/outline';
import NotificationSaved from './components/NotificationSaved';
import Breadcrumbs from '../components/Breadcrumbs';
import SecurityModal from './components/SecurityModal';

const SavedForm = () => {
  const { auth } = useAuth();
  const { formId } = useParams();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const [form, setForm] = useState({});

  const [isFetching, setIsFetching] = useState(false);
  const [show, setShow] = useState(false);

  const [currentTab, setCurrentTab] = useState(tabsTitles[0]);
  const [missionName, setMissionName] = useState('');
  const [missionFormStatus, setMissionFormStatus] = useState('');
  const [missionForm, setMissionForm] = useState(constantsModule.missionForminitState);
  const [isSecurityModalOpen, setIsSecurityModalOpen] = useState(false);

  const errors = useValidateMissionForm({ missionForm });

  const getUserSavedFormRequest = {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  };

  const [data, loading, error] = useFetchCall(
    endpoints.USER_SAVED_FORM(formId),
    getUserSavedFormRequest
  );

  useEffect(() => {
    return () => {
      setIsFetching(false);
      setCurrentTab(tabsTitles[0]);
      setMissionName('');
      setMissionFormStatus('');
      setMissionForm(constantsModule.missionForminitState);
    };
  }, []);

  useEffect(() => {
    if (error) {
      if (error.success === false) {
        if (error.message === 'Saved form not found') {
          navigate(constantsRoute.ROUTE_NOT_FOUND);
        } else {
          navigate(constantsRoute.ROUTE_HOME);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.data) {
      setForm(data.data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (form?.data) {
      setMissionForm(form.data);
      setMissionFormStatus(form.status);
      setMissionName(form.name);
    }
  }, [form]);

  const handleSave = async (e) => {
    e.preventDefault();

    const savedMissionFormRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: formId,
        user: auth.id,
        name: missionName,
        version: endpoints.APP_VERSION,
        data: missionForm,
      }),
    };

    try {
      setIsFetching(true);
      const response = await fetch(endpoints.API + endpoints.SAVE_FORM, savedMissionFormRequest);
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        // Display success
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 1500);
      }
    } catch (err) {
      console.log(err);
    }
    setIsFetching(false);
  };
  const handleExportForm = async (e) => {
    // e.preventDefault();

    const exportMissionFormRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: auth.id,
        id: formId,
        name: missionName,
        version: endpoints.APP_VERSION,
        data: missionForm,
      }),
    };

    try {
      setIsFetching(true);
      const response = await fetch(
        endpoints.API + endpoints.EXPORT_SAVED_FORM,
        exportMissionFormRequest
      );
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        const res = await response.json();
        navigate(constantsRoute.ROUTE_USER_FORM(res.data.user, res.data.id));
      }
    } catch (err) {
      console.log(err);
    }
    setIsFetching(false);
  };

  return !loading ? (
    <>
      <SecurityModal
        message="By clicking submit, I accept that the information contained in this form is sent to the SSR applicant. I confirm that the information provided about my mission is correct and accurate."
        isOpen={isSecurityModalOpen}
        setIsOpen={setIsSecurityModalOpen}
        onClickFunc={handleExportForm}
      />
      <NotificationSaved show={show} setShow={setShow} />
      <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pl-4 pt-8 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
        <Breadcrumbs
          pages={[
            { name: 'Saved Form', href: constantsRoute.ROUTE_HOME },
            { name: `${form?.name}`, href: '#' },
          ]}
        />
      </div>
      <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pl-4 pt-6">
        {missionFormStatus !== 'duplicated' && (
          <div className="flex">
            <span className="mt-1 sm:ml-3">
              <button
                type="button"
                onClick={handleSave}
                disabled={isFetching}
                className={`inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600`}>
                {isFetching ? (
                  <SpinnerIcon />
                ) : (
                  <SaveIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                )}
                Save
              </button>
            </span>
            <span className="mt-1 sm:ml-3">
              <button
                type="button"
                onClick={() => setIsSecurityModalOpen(true)}
                disabled={errors.length !== 0 || isFetching}
                className={`${
                  errors.length !== 0 && 'opacity-40'
                }  inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600`}>
                {isFetching ? (
                  <SpinnerIcon />
                ) : (
                  <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                )}
                Submit
              </button>
            </span>
          </div>
        )}

        {MissionForm({
          currentTab,
          setCurrentTab,
          tabsTitles,
          missionForm,
          setMissionForm,
          displayType:
            missionFormStatus === 'duplicated'
              ? constantsModule.DISPLAY_ADMIN_COMMENTS_AND_USER_COMMENTS_TO_USER
              : constantsModule.DISPLAY_ADMIN_COMMENTS_AND_INPUT_COMMENTS_TO_USER,
        })}
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default SavedForm;
