import useAuth from '../../hooks/useAuth';
import { ROLES } from '../../utils/routing/constants';
import AdminDashboard from '../admin/AdminDashboard';
import UserDashboard from '../user/UserDashboard';

const Dashboard = () => {
  const { auth } = useAuth();

  if (auth.roles.includes(ROLES.Admin)) {
    return <AdminDashboard />;
  } else if (auth.roles.includes(ROLES.User)) {
    return <UserDashboard />;
  }
  return null;
};

export default Dashboard;
