import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import * as constantsRoute from '../../../utils/routing/constants';
import * as constants from '../../../utils/constants';
import { BadgeCheckIcon, ClipboardCheckIcon, ClipboardListIcon } from '@heroicons/react/outline';

const FormTable = ({ data, type, userId }) => {
  const getLinkToForm = (formId, type) => {
    switch (type) {
      case constants.FORM_TYPE.Submitted:
        return constantsRoute.ROUTE_USER_FORM(userId, formId);
      case constants.FORM_TYPE.Saved:
        return constantsRoute.ROUTE_USER_SAVED_FORM(userId, formId);
      case constants.FORM_TYPE.Rated:
        return constantsRoute.ROUTE_USER_RATED_FORM(userId, formId);
      default:
        return constantsRoute.ROUTE_HOME;
    }
  };

  const displayStatus = (status) => {
    switch (status) {
      case 'saved':
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            Saved
          </span>
        );
      case 'changes-requested':
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            Changes requested
          </span>
        );
      case 'duplicated':
        return (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
            Duplicated
          </span>
        );
      default:
        return <></>;
    }
  };

  const displayFormTypeIcon = () => {
    switch (type) {
      case 'Saved':
        return <ClipboardListIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />;
      case 'Submitted':
        return <ClipboardCheckIcon className=" h-5 w-5 text-gray-500" aria-hidden="true" />;
      case 'Rated':
        return <BadgeCheckIcon className=" h-5 w-5 text-gray-500" aria-hidden="true" />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="mt-10 sm:hidden">
        <div className="px-4 sm:px-6">
          <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
            {constants.FORM_TYPE_TEXT[type]}
          </h2>
        </div>
        <ul className="mt-3 border-t border-gray-200 divide-y divide-gray-100">
          {data.length ? (
            React.Children.toArray(
              data.map((form) => (
                <li>
                  <Link
                    to={getLinkToForm(form.id, type)}
                    className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
                    <span className="flex items-center truncate space-x-3">
                      <span className="font-medium truncate text-sm leading-6">{form.name}</span>
                    </span>
                    <ChevronRightIcon
                      className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Link>
                </li>
              ))
            )
          ) : (
            <li>
              <p className="mt-1 px-4 py-4 text-sm text-gray-500">
                No {constants.FORM_TYPE_TEXT[type].toLowerCase()}.
              </p>
            </li>
          )}
        </ul>
      </div>
      <div className="hidden mt-8 sm:block">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr className="border-t border-gray-200">
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="flex">
                    {displayFormTypeIcon()}
                    <div className="mt-[3px] ml-1">{constants.FORM_TYPE_TEXT[type]}</div>
                  </span>
                </th>

                {data.length ? (
                  <>
                    {type === constants.FORM_TYPE.Saved && (
                      <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                    )}
                    <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last updated
                    </th>
                  </>
                ) : (
                  <></>
                )}
                <th className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider" />
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {data.length ? (
                React.Children.toArray(
                  data.map((form) => (
                    <tr>
                      <td className="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="flex items-center space-x-3 lg:pl-2">
                          <Link
                            to={getLinkToForm(form.id, type)}
                            className="truncate hover:text-gray-600">
                            <span>{form.name}</span>
                          </Link>
                        </div>
                      </td>
                      {type === constants.FORM_TYPE.Saved && (
                        <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                          {displayStatus(form.status)}
                        </td>
                      )}
                      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                        {constants.formatDate(form.date)}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={getLinkToForm(form.id, type)}
                          className="text-indigo-600 hover:text-indigo-900">
                          View
                        </Link>
                      </td>
                    </tr>
                  ))
                )
              ) : (
                <tr>
                  <td>
                    <div className="mt-1 px-4 py-4 text-sm text-gray-500">
                      No {constants.FORM_TYPE_TEXT[type].toLowerCase()} yet.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FormTable;
