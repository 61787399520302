import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import useFetchCall from '../../api/useFetchCall';
import * as endpoints from '../../utils/endpoints';

import AdminUserFormsTable from './components/AdminUserFormsTable';
import Loading from '../Loading';

const UserForms = () => {
  const { userId } = useParams();
  const accessToken = localStorage.getItem('accessToken');

  const [submittedForms, setSubmittedForms] = useState([]);
  const [ratedForms, setRatedForms] = useState([]);

  const getRequest = {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  };

  const [dataSubmittedForms, loadingSubmittedForms] = useFetchCall(
    endpoints.USER_FORMS(userId),
    getRequest
  );
  const [dataRatedForms, loadingRatedForms] = useFetchCall(
    endpoints.USER_RATED_FORMS(userId),
    getRequest
  );

  const [dataUser, loadingDataUser] = useFetchCall(endpoints.PROFILE(userId), getRequest);

  useEffect(() => {
    if (dataSubmittedForms?.data) {
      setSubmittedForms(dataSubmittedForms.data);
    }
  }, [dataSubmittedForms]);

  useEffect(() => {
    if (dataRatedForms?.data) {
      setRatedForms(dataRatedForms.data);
    }
  }, [dataRatedForms]);

  return (
    <>
      <section>
        {!loadingDataUser ? (
          <>
            <div className="lg:pl-[calc(14vw)] lg:pr-[calc(14vw)] pl-4 pt-8 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Dashboard User: {dataUser.email}
            </div>
          </>
        ) : (
          <Loading />
        )}
      </section>
      {!loadingSubmittedForms && !loadingRatedForms && (
        <AdminUserFormsTable
          userId={userId}
          submittedForms={submittedForms}
          loadingSubmittedForms={loadingSubmittedForms}
          ratedForms={ratedForms}
          loadingRatedForms={loadingRatedForms}
        />
      )}
    </>
  );
};

export default UserForms;
