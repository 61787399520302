import React, { useEffect, useReducer } from 'react';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/outline';

import * as constantsModule from '../../../utils/modules/constants';
import * as endpoints from '../../../utils/endpoints';
import LazyLoadImage from '../../components/LazyLoadImage';
import ModuleHistogramCumulativeScore from '../../user/components/ModuleHistogramCumulativeScore';
import ModuleWebChart from '../../user/components/ModuleWebChart';
import getBadge from '../../user/components/getBadge';
import useFetchCall from '../../../api/useFetchCall';
import { buildInitState } from '../../user/question/utils/utils';
import Question from '../../user/question/Question';
import { computeTotalScoreModule } from '../../../utils/modules/scoring';

const mainTabs = [
  { name: 'Rating', current: true },
  { name: 'Form Comparaison', current: false },
];

const emptyScore = {
  missionIndex: 0,
  collisionAvoidance: 0,
  dataSharing: 0,
  detectionIdentificationTracking: 0,
  applicationOfDesignAndOperation: 0,
  externalServices: 0,
  bonus: 0,
  total: 0,
};
const emptyComparisonScore = [emptyScore, emptyScore];

const areElementsEqual = (a, b) => {
  // Check if both are arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
  return a === b;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const percentStrToPercentNum = (string) => {
  return Number((string * 100).toFixed(2));
};

const CompareFormResults = () => {
  const accessToken = localStorage.getItem('accessToken');

  const [state, updateState] = useReducer(
    (prev, next) => {
      const newState = { ...prev, ...next };
      return newState;
    },
    {
      formIds: [],
      baseForm: {},
      comparedToForm: {},
      score: emptyComparisonScore,
      mainTab: mainTabs[0],
      baseFormQuestions: [],
      comparedToFormQuestions: [],
    }
  );

  const getRequest = {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  };

  const [dataRatedForms, loadingRatedForms] = useFetchCall(endpoints.SUBMITTED_FORMS, getRequest);
  const [dataBaseForm] = useFetchCall(endpoints.USER_FORM(state.baseForm?.id), getRequest);
  const [dataComparedToForm] = useFetchCall(
    endpoints.USER_FORM(state.comparedToForm?.id),
    getRequest
  );

  useEffect(() => {
    if (dataRatedForms?.data && !loadingRatedForms) {
      updateState({ formIds: [...dataRatedForms.data] });
    }
  }, [dataRatedForms, loadingRatedForms]);

  useEffect(() => {
    let scoreBaseForm = structuredClone(state.score);
    if (dataBaseForm) {
      scoreBaseForm[1] = computeTotalScoreModule(dataBaseForm);
    } else {
      scoreBaseForm = structuredClone(emptyComparisonScore);
      updateState({ comparedToForm: {} });
    }
    updateState({ score: scoreBaseForm });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBaseForm]);

  useEffect(() => {
    const scoreCompareToForm = structuredClone(state.score);
    if (dataComparedToForm) {
      scoreCompareToForm[0] = computeTotalScoreModule(dataComparedToForm);
    } else {
      scoreCompareToForm[0] = structuredClone(emptyScore);
    }
    updateState({ score: scoreCompareToForm });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataComparedToForm]);

  const getQuestionWithAdminComments = (data) => {
    const {
      applicationOfDesignAndOperation,
      collisionAvoidance,
      dataSharing,
      detectionIdentificationTracking,
      externalServices,
      missionIndex,
    } = data;

    const filterQuestionsWithAdminComments = (quest) => quest?.adminComments?.length > 0;

    return [
      ...applicationOfDesignAndOperation.filter(filterQuestionsWithAdminComments),
      ...collisionAvoidance.filter(filterQuestionsWithAdminComments),
      ...dataSharing.filter(filterQuestionsWithAdminComments),
      ...detectionIdentificationTracking.filter(filterQuestionsWithAdminComments),
      ...externalServices.filter(filterQuestionsWithAdminComments),
      ...missionIndex.filter(filterQuestionsWithAdminComments),
    ];
  };

  const getDataQuestionsFlattened = (data) => {
    const {
      applicationOfDesignAndOperation,
      collisionAvoidance,
      dataSharing,
      detectionIdentificationTracking,
      externalServices,
      missionIndex,
    } = data;

    return [
      ...applicationOfDesignAndOperation,
      ...collisionAvoidance,
      ...dataSharing,
      ...detectionIdentificationTracking,
      ...externalServices,
      ...missionIndex,
    ];
  };

  useEffect(() => {
    if (dataBaseForm?.data && dataComparedToForm?.data) {
      const questionsWithAdminComments = getQuestionWithAdminComments(
        dataComparedToForm?.data.data
      );
      const baseFormQuestions = getDataQuestionsFlattened(dataBaseForm?.data.data);

      const commonQuestions = [];

      for (const baseQuestion of baseFormQuestions) {
        for (const adminCommentsQuestion of questionsWithAdminComments) {
          // check if both the question text and the choices are equal
          // meaning it's the same question we are looking for
          const elementsEqual =
            areElementsEqual(baseQuestion.question, adminCommentsQuestion.question) &&
            areElementsEqual(baseQuestion.choices, adminCommentsQuestion.choices);
          const alreadyExists = commonQuestions.includes(baseQuestion);

          if (!alreadyExists && elementsEqual) {
            commonQuestions.push(baseQuestion);
          }
        }
      }
      updateState({
        baseFormQuestions: commonQuestions,
        comparedToFormQuestions: questionsWithAdminComments,
      });
    }
  }, [dataBaseForm, dataComparedToForm]);

  const displayComparaisonTable = () => {
    return (
      <table className="table-fixed border-collapse border-gray-300 my-4">
        <thead>
          <tr>
            <th className="border border-gray-300">Question</th>
            <th className="border border-gray-300">User answer</th>
            <th className="border border-gray-300">Admin Recommendations</th>
            <th className="border border-gray-300">Comments</th>
          </tr>
        </thead>
        <tbody>
          {React.Children.toArray(
            state.baseFormQuestions.map((q, i) => (
              <tr>
                <td className="border p-2 border-gray-300">
                  <Question
                    displayType={constantsModule.DISPLAY_QUESTION}
                    question={buildInitState(q)}
                    handleChanges={() => {}}
                  />
                </td>
                <td className="border p-2 border-gray-300">
                  <Question
                    displayType={constantsModule.DISPLAY_ANSWERS}
                    question={buildInitState(q)}
                    handleChanges={() => {}}
                  />
                </td>
                <td className="border p-2 border-gray-300">
                  <Question
                    displayType={constantsModule.DISPLAY_ANSWERS}
                    question={buildInitState(state.comparedToFormQuestions[i])}
                    handleChanges={() => {}}
                  />
                </td>
                <td className="border p-2 border-gray-300">
                  {state.comparedToFormQuestions[i].adminComments}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  };

  const SwitchMainTabs = () => {
    switch (state.mainTab.name) {
      case 'Rating':
        return (
          <>
            <div className="flex items-center justify-center pb-2">
              <div className="flex items-center justify-center first-letter:bg-gray-200 shadow rounded-lg w-96">
                <LazyLoadImage
                  className="h-[120px] mr-1"
                  src={getBadge({
                    tier_score: state.score[1].total,
                    bonus_score: state.score[1].bonus,
                  })}
                  alt="badgeFrom"
                />
                To
                <LazyLoadImage
                  className="h-[200px]"
                  src={getBadge({
                    tier_score: state.score[0].total,
                    bonus_score: state.score[0].bonus,
                  })}
                  alt="badgeTo"
                />
              </div>
            </div>
            <dl className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-base font-normal text-gray-900">Tier Score</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-black">
                    {percentStrToPercentNum(state.score[0].total)} %
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {percentStrToPercentNum(state.score[1].total)} %
                    </span>
                  </div>

                  <div
                    className={classNames(
                      state.score[0].total > state.score[1].total
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}>
                    {state.score[0].total > state.score[1].total ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span className="sr-only">
                      {state.score[0].total > state.score[1].total ? 'Increased' : 'Decreased'} by
                    </span>
                    {percentStrToPercentNum(state.score[0].total - state.score[1].total)} %
                  </div>
                </dd>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-base font-normal text-gray-900">Bonus Score</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-black">
                    {percentStrToPercentNum(state.score[0].bonus)} %
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {percentStrToPercentNum(state.score[1].bonus)} %
                    </span>
                  </div>

                  <div
                    className={classNames(
                      state.score[0].bonus > state.score[1].bonus
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}>
                    {state.score[0].bonus > state.score[1].bonus ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span className="sr-only">
                      {state.score[0].bonus > state.score[1].bonus ? 'Increased' : 'Decreased'} by
                    </span>
                    {percentStrToPercentNum(state.score[0].bonus - state.score[1].bonus)} %
                  </div>
                </dd>
              </div>
            </dl>

            <dl className="mt-5 mb-1 grid grid-cols-1 rounded-t-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">Mission Index</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-700">
                    {percentStrToPercentNum(state.score[0].missionIndex)} %
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {percentStrToPercentNum(state.score[1].missionIndex)} %
                    </span>
                  </div>

                  <div
                    className={classNames(
                      state.score[0].missionIndex > state.score[1].missionIndex
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}>
                    {state.score[0].missionIndex > state.score[1].missionIndex ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span className="sr-only">
                      {state.score[0].missionIndex > state.score[1].missionIndex
                        ? 'Increased'
                        : 'Decreased'}
                      by
                    </span>
                    {percentStrToPercentNum(
                      state.score[0].missionIndex - state.score[1].missionIndex
                    )}
                    %
                  </div>
                </dd>
              </div>

              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">
                  Collision Avoidance Capabilities
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-700">
                    {percentStrToPercentNum(state.score[0].collisionAvoidance)} %
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {percentStrToPercentNum(state.score[1].collisionAvoidance)} %
                    </span>
                  </div>

                  <div
                    className={classNames(
                      state.score[0].collisionAvoidance > state.score[1].collisionAvoidance
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}>
                    {state.score[0].collisionAvoidance > state.score[1].collisionAvoidance ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span className="sr-only">
                      {state.score[0].collisionAvoidance > state.score[1].collisionAvoidance
                        ? 'Increased'
                        : 'Decreased'}
                      by
                    </span>
                    {percentStrToPercentNum(
                      state.score[0].collisionAvoidance - state.score[1].collisionAvoidance
                    )}
                    %
                  </div>
                </dd>
              </div>

              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">Data Sharing</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-700">
                    {percentStrToPercentNum(state.score[0].dataSharing)} %
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {percentStrToPercentNum(state.score[1].dataSharing)} %
                    </span>
                  </div>

                  <div
                    className={classNames(
                      state.score[0].dataSharing > state.score[1].dataSharing
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}>
                    {state.score[0].dataSharing > state.score[1].dataSharing ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span className="sr-only">
                      {state.score[0].dataSharing > state.score[1].dataSharing
                        ? 'Increased'
                        : 'Decreased'}
                      by
                    </span>
                    {percentStrToPercentNum(
                      state.score[0].dataSharing - state.score[1].dataSharing
                    )}
                    %
                  </div>
                </dd>
              </div>
            </dl>

            <dl className="mb-5 grid grid-cols-1 rounded-b-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">
                  Detection, Identification and Tracking
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-700">
                    {percentStrToPercentNum(state.score[0].detectionIdentificationTracking)} %
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {percentStrToPercentNum(state.score[1].detectionIdentificationTracking)}%
                    </span>
                  </div>

                  <div
                    className={classNames(
                      state.score[0].detectionIdentificationTracking >
                        state.score[1].detectionIdentificationTracking
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}>
                    {state.score[0].detectionIdentificationTracking >
                    state.score[1].detectionIdentificationTracking ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span className="sr-only">
                      {state.score[0].detectionIdentificationTracking >
                      state.score[1].detectionIdentificationTracking
                        ? 'Increased'
                        : 'Decreased'}
                      by
                    </span>
                    {percentStrToPercentNum(
                      state.score[0].detectionIdentificationTracking -
                        state.score[1].detectionIdentificationTracking
                    )}
                    %
                  </div>
                </dd>
              </div>

              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">
                  Application of Design and Operation Standards
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-700">
                    {percentStrToPercentNum(state.score[0].applicationOfDesignAndOperation)} %
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {percentStrToPercentNum(state.score[1].applicationOfDesignAndOperation)}%
                    </span>
                  </div>

                  <div
                    className={classNames(
                      state.score[0].applicationOfDesignAndOperation >
                        state.score[1].applicationOfDesignAndOperation
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}>
                    {state.score[0].applicationOfDesignAndOperation >
                    state.score[1].applicationOfDesignAndOperation ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span className="sr-only">
                      {state.score[0].applicationOfDesignAndOperation >
                      state.score[1].applicationOfDesignAndOperation
                        ? 'Increased'
                        : 'Decreased'}
                      by
                    </span>
                    {percentStrToPercentNum(
                      state.score[0].applicationOfDesignAndOperation -
                        state.score[1].applicationOfDesignAndOperation
                    )}
                    %
                  </div>
                </dd>
              </div>

              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">External Services</dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-gray-700">
                    {percentStrToPercentNum(state.score[0].externalServices)} %
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {percentStrToPercentNum(state.score[1].externalServices)} %
                    </span>
                  </div>

                  <div
                    className={classNames(
                      state.score[0].externalServices > state.score[1].externalServices
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}>
                    {state.score[0].externalServices > state.score[1].externalServices ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span className="sr-only">
                      {state.score[0].externalServices > state.score[1].externalServices
                        ? 'Increased'
                        : 'Decreased'}
                      by
                    </span>
                    {percentStrToPercentNum(
                      state.score[0].externalServices - state.score[1].externalServices
                    )}
                    %
                  </div>
                </dd>
              </div>
            </dl>

            <div className="grid grid-cols-2 pb-8">
              <ModuleWebChart
                results={[
                  { name: state.baseForm?.name, score: state.score[1] },
                  { name: state.comparedToForm?.name, score: state.score[0] },
                ]}
              />
              <ModuleHistogramCumulativeScore
                results={[
                  { name: state.baseForm?.name, score: state.score[1] },
                  { name: state.comparedToForm?.name, score: state.score[0] },
                ]}
              />
            </div>
          </>
        );
      case 'Form Comparaison':
        return displayComparaisonTable();
      default:
        break;
    }
  };

  const DisplayTabs = () => {
    return (
      <nav className="-mb-px flex space-x-8">
        {React.Children.toArray(
          mainTabs.map((tab) => (
            <div
              onClick={() => updateState({ mainTab: tab })}
              className={classNames(
                state.mainTab.name === tab.name
                  ? 'border-red-500 text-red-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'cursor-pointer whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
              )}>
              {tab.name}
            </div>
          ))
        )}
      </nav>
    );
  };

  return (
    <>
      <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pl-4 pt-6 ">
        <div className="border-b border-gray-200 mb-2">
          <div className="sm:flex sm:items-baseline ">
            <h3 className="text-lg leading-6 pl-4 font-medium text-gray-900">
              Compare Form Results
            </h3>
            <select
              name="baseForm"
              value={state.baseForm?.id || ''}
              onChange={(e) =>
                updateState({ baseForm: state.formIds.find(({ id }) => e.target.value === id) })
              }
              className="mt-1 mr-2 ml-2 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md">
              <option value="" label="Base form" />
              {React.Children.toArray(
                state.formIds.map(({ id, name }) => <option value={id}>{name}</option>)
              )}
            </select>
            {state.baseForm?.id &&
              state.formIds.filter(({ id }) => state.baseForm?.id !== id).length > 0 && (
                <select
                  name="comparedToForm"
                  value={state.comparedToForm?.id || ''}
                  onChange={(e) =>
                    updateState({
                      comparedToForm: state.formIds.find(({ id }) => e.target.value === id),
                    })
                  }
                  className="mt-1 focus:ring-gray-600 focus:border-gray-600 block  shadow-sm sm:text-sm border-gray-300 rounded-md">
                  <option value="" label="Compared to form" />
                  {React.Children.toArray(
                    state.formIds
                      .filter(({ id }) => state.baseForm?.id !== id)
                      .map(({ id, name }) => <option value={id}>{name}</option>)
                  )}
                </select>
              )}

            <div className="mt-4 sm:mt-0 sm:ml-10">{DisplayTabs()}</div>
          </div>
        </div>
        {SwitchMainTabs()}
      </div>
    </>
  );
};

export default CompareFormResults;
