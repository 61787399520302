const APP_VERSION = '1.0.0';

const dev = {
  // API_URL: 'http://localhost:5001/api',
  API_URL: 'https://dev-backend-ssr.jcloud-ver-jpc.ik-server.com/api',
  APP_VERSION,
};

const prod = {
  API_URL: 'https://backend-ssr.jcloud-ver-jpc.ik-server.com/api',
  APP_VERSION,
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

// eslint-disable-next-line import/no-anonymous-default-export
export default config;
