import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import step1 from '../../../assets/generalInstructions/step1.png';
import step2 from '../../../assets/generalInstructions/step2.png';
import step3 from '../../../assets/generalInstructions/step3.png';
import step4 from '../../../assets/generalInstructions/step4.png';
import step5 from '../../../assets/generalInstructions/step5.png';
import step6 from '../../../assets/generalInstructions/step6.png';
import LazyLoadImage from '../../components/LazyLoadImage';

const generalInstructionsSteps = [
  { name: 'Step 1', title: 'Mission created!', src: step1 },
  { name: 'Step 2', title: 'Filling the inputs', src: step2 },
  { name: 'Step 3', title: 'Reminder on the verification level (1/2)', src: step3 },
  { name: 'Step 4', title: 'Reminder on the verification level (2/2)', src: step4 },
  { name: 'Step 5', title: 'Save and Submit your form', src: step5 },
  { name: 'Step 6', title: 'Adjustments from the SSR team initial review', src: step6 },
];

const StepsGeneralInstructions = ({ currentStep, setCurrentStep }) => {
  return (
    <nav className="flex items-center  justify-center mt-4" aria-label="Progress">
      <p className="text-sm font-medium">Step {currentStep + 1} of 6</p>
      <ol className="ml-8 flex items-center space-x-5">
        {React.Children.toArray(
          generalInstructionsSteps.map((_, idx) => (
            <li>
              {currentStep > idx ? (
                <div
                  className="block w-2.5 h-2.5 bg-indigo-400 rounded-full hover:bg-indigo-900"
                  onClick={() => setCurrentStep(idx)}>
                  <span className="sr-only">{`Step ${idx + 1}`}</span>
                </div>
              ) : idx === currentStep ? (
                <div className="relative flex items-center justify-center">
                  <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                    <span className="w-full h-full rounded-full bg-indigo-200" />
                  </span>
                  <span
                    className="relative block w-2.5 h-2.5 bg-indigo-600 rounded-full"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{`Step ${idx + 1}`}</span>
                </div>
              ) : (
                <div
                  className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400"
                  onClick={() => setCurrentStep(idx)}>
                  <span className="sr-only">{`Step ${idx + 1}`}</span>
                </div>
              )}
            </li>
          ))
        )}
      </ol>
    </nav>
  );
};

export default function GeneralInstructionsModal({ open, setOpen }) {
  const [currentStep, setCurrentStep] = useState(0);
  const generalInstructionsModal = localStorage.getItem('generalInstructionsModal');

  useEffect(() => {
    if (!open && generalInstructionsModal === null)
      localStorage.setItem('generalInstructionsModal', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const displayGeneralInstructionsStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div>
            <div>
              Your mission was successfully created, you can modify its name on the “Mission Name”
              textbox. In order to provide the necessary input to rate your mission, you will have
              to fill six Space Sustainability Rating input forms (one per SSR module). These forms
              are located in the six different tabs you can see at the top of the page.
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div>
              Each tab contains the necessary inputs needed from the applicant, that can either be
              questions or text boxes to fill. The format entered in the boxes shall respect the
              data validation requirements written in red below the inputs. Each input also contains
              a verification level that the applicant is requested to fill in.{' '}
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div>
              A weighting factor will be applied on the points earned by each input depending on the
              level of verifiability on every item of the rating. The SSR provides additional credit
              for statements by operators that can be supported by additional levels of
              verification. Please
              <b> use the comment text box to justify how the information can be verified </b>
              and/or in which document it can be verified.
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div>
              The different levels of verification are referenced on the SSR website,
              <a
                className="text-indigo-600 underline hover:text-indigo-700"
                href="https://spacesustainabilityrating.org/the-rating/">
                {' '}
                “general methodology”
              </a>{' '}
              , or in the documentation handed by the SSR team. If you believe a level of
              verification is not relevant for your mission in its current phase or concept, please
              tick the N/A verification level and include a brief remark explaining why in the
              comment section of the comment text box.
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <div>
              Once you start to fill a form, two features are available:
              <ul className="list-disc pl-12">
                <li>
                  Save: This option allows saving the current status of the input form. Once saved,
                  your form will appear as an “Ongoing form” in your dashboard. In order to resume
                  the input collection, click on the “view” button of your ongoing form.
                </li>
                <li>
                  Submit: This option will only function if all the input forms are complete,
                  meaning that inputs are correctly entered (including all the data verification
                  levels). Once used, this function will send the input form to the SSR team that
                  will then proceed to review it and compute the score. Once submitted, the form
                  will appear as an “submitted form” in your dashboard.
                </li>
              </ul>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <div>
              Please know that once submitted, the SSR team might request additional information or
              clarification in which case the form be sent back to the “ongoing forms” category with
              a “Request for changes” mention. Any comment by the SSR team will appear in the input
              comment section. After the correct submission of your form, the SSR team will proceed
              to the score computation and get back to you shortly (usually within 2 to 3 weeks). Do
              not hesitate to ask any remaining questions on the comment text box located on the
              right side of the input. For urgent matters, contact the SSR team at
              <a
                className="text-indigo-600 underline hover:text-indigo-700"
                href="mailto: adrien.saada@epfl.ch">
                {' '}
                adrien.saada@epfl.ch
              </a>{' '}
              .
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div>
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center leading-6 font-medium text-gray-900">
                    {generalInstructionsSteps[currentStep].title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div>
                      {displayGeneralInstructionsStep()}
                      <LazyLoadImage
                        className="ml-2"
                        src={generalInstructionsSteps[currentStep].src}
                      />
                      <div>
                        <div className="flex">
                          <div className="flex flex-1 justify-between">
                            <button
                              onClick={() => {
                                if (currentStep > 0) setCurrentStep(currentStep - 1);
                              }}
                              disabled={currentStep === 0}
                              className={`${
                                currentStep === 0 && 'invisible'
                              } relative cursor-pointer inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50`}>
                              Previous
                            </button>
                            <button
                              onClick={() => {
                                if (currentStep < 5) setCurrentStep(currentStep + 1);
                              }}
                              disabled={currentStep === 5}
                              className={`${
                                currentStep === 5 && 'invisible'
                              } relative cursor-pointer inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50`}>
                              Next
                            </button>
                          </div>
                        </div>
                        <StepsGeneralInstructions
                          currentStep={currentStep}
                          setCurrentStep={setCurrentStep}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                  onClick={() => setOpen(false)}>
                  Create Mission
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
