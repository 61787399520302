import * as constantsModule from '../../../utils/modules/constants';

import * as questionComponents from './components';

const Question = ({ question, handleChanges, displayType }) => {
  const handleVerification = (e) => {
    handleChanges({
      ...question,
      verification: e.target.value,
    });
  };

  const handleComments = (e, type) => {
    switch (type) {
      case 'user':
        handleChanges({
          ...question,
          userComments: e.target.value,
        });
        break;
      case 'admin':
        handleChanges({
          ...question,
          adminComments: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  const handleAnswer = (e, type) => {
    switch (type) {
      case 'radio':
        handleChanges({
          ...question,
          answerRadio: e.target.value,
        });
        break;
      case 'input':
        handleChanges({
          ...question,
          answerInput: e.target.value,
        });
        break;
      default:
        handleChanges({
          ...question,
          answer: e.target.value,
        });
        break;
    }
  };

  const handleChange = (key) => (e) => {
    switch (question.type) {
      case constantsModule.INPUT_VERIF:
        switch (key) {
          case 'answer':
            handleAnswer(e);
            break;
          case 'verification':
            handleVerification(e);
            break;
          case 'userComments':
            handleComments(e, 'user');
            break;
          case 'adminComments':
            handleComments(e, 'admin');
            break;
          default:
            break;
        }
        break;
      case constantsModule.DROPDOWN:
        switch (key) {
          case 'answer':
            handleAnswer(e);
            break;
          case 'userComments':
            handleComments(e, 'user');
            break;
          case 'adminComments':
            handleComments(e, 'admin');
            break;
          default:
            break;
        }
        break;
      case constantsModule.RADIO_HIDE_COMPONENTS:
        switch (key) {
          case 'answer':
            handleAnswer(e);
            break;
          case 'userComments':
            handleComments(e, 'user');
            break;
          case 'adminComments':
            handleComments(e, 'admin');
            break;
          default:
            break;
        }
        break;
      case constantsModule.CHECKBOX_CONDITION_VERIF:
        switch (key) {
          case 'answer':
            const position = question.choices.findIndex((item) => item === e.target.value);
            let updatedAnswers = [];

            if (question.conditionValuesOnAnswer.verification[position]?.length === 1) {
              updatedAnswers = question.answer.map((item, index) =>
                index === position ? !item : false
              );
              handleChanges({
                ...question,
                answer: updatedAnswers,
                verification: question.conditionValuesOnAnswer.verification[position][0],
              });
            } else {
              updatedAnswers = question.answer.map((item, index) =>
                index === position ? !item : item
              );
              handleChanges({ ...question, answer: updatedAnswers });
            }
            break;
          case 'verification':
            handleVerification(e);
            break;
          case 'userComments':
            handleComments(e, 'user');
            break;
          case 'adminComments':
            handleComments(e, 'admin');
            break;
          default:
            break;
        }
        break;
      case constantsModule.BONUS_CHECKBOX_CONDITION_VERIF:
        switch (key) {
          case 'answer':
            const position = question.choices.findIndex((item) => item === e.target.value);
            let updatedAnswers = [];

            if (question.conditionValuesOnAnswer.verification[position]?.length === 1) {
              updatedAnswers = question.answer.map((item, index) =>
                index === position ? !item : false
              );
              handleChanges({
                ...question,
                answer: updatedAnswers,
                verification: question.conditionValuesOnAnswer.verification[position][0],
              });
            } else {
              updatedAnswers = question.answer.map((item, index) =>
                index === position ? !item : item
              );
              handleChanges({ ...question, answer: updatedAnswers });
            }
            break;
          case 'verification':
            handleVerification(e);
            break;
          case 'userComments':
            handleComments(e, 'user');
            break;
          case 'adminComments':
            handleComments(e, 'admin');
            break;
          default:
            break;
        }
        break;
      case constantsModule.RADIO_CONDITION_VERIF:
        switch (key) {
          case 'answer':
            const position = question.choices.findIndex((item) => item === e.target.value);
            if (question.conditionValuesOnAnswer.verification[position]?.length === 1) {
              handleChanges({
                ...question,
                answer: e.target.value,
                verification: question.conditionValuesOnAnswer.verification[position][0],
              });
            } else {
              handleChanges({
                ...question,
                answer: e.target.value,
              });
            }
            break;
          case 'verification':
            handleVerification(e);
            break;
          case 'userComments':
            handleComments(e, 'user');
            break;
          case 'adminComments':
            handleComments(e, 'admin');
            break;
          default:
            break;
        }
        break;
      case constantsModule.BONUS_RADIO_CONDITION_VERIF:
        switch (key) {
          case 'answer':
            const position = question.choices.findIndex((item) => item === e.target.value);
            if (question.conditionValuesOnAnswer.verification[position]?.length === 1) {
              handleChanges({
                ...question,
                answer: e.target.value,
                verification: question.conditionValuesOnAnswer.verification[position][0],
              });
            } else {
              handleChanges({
                ...question,
                answer: e.target.value,
              });
            }
            break;
          case 'verification':
            handleVerification(e);
            break;
          case 'userComments':
            handleComments(e, 'user');
            break;
          case 'adminComments':
            handleComments(e, 'admin');
            break;
          default:
            break;
        }
        break;
      case constantsModule.RADIO_CONDITION_INPUT_VERIF:
        switch (key) {
          case 'answerRadio':
            const position = question.choices.findIndex((item) => item === e.target.value);
            if (question.conditionValuesOnAnswer.verification[position]?.length === 1) {
              handleChanges({
                ...question,
                answerRadio: e.target.value,
                answerInput: question.conditionValuesOnAnswer.answerInput[position],
                verification: question.conditionValuesOnAnswer.verification[position][0],
              });
            } else {
              handleChanges({
                ...question,
                answerRadio: e.target.value,
              });
            }
            break;
          case 'answerInput':
            handleAnswer(e, 'input');
            break;
          case 'verification':
            handleVerification(e);
            break;
          case 'userComments':
            handleComments(e, 'user');
            break;
          case 'adminComments':
            handleComments(e, 'admin');
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  const inputPropsQuestionsComponents = {
    question,
    handleChange,
    handleChanges,
    displayType,
  };

  const displayQuestion = () => {
    switch (question.type) {
      case constantsModule.INPUT_VERIF:
        return questionComponents.InputVerif(inputPropsQuestionsComponents);
      case constantsModule.CHECKBOX_CONDITION_VERIF:
        return questionComponents.CheckboxConditionVerif(inputPropsQuestionsComponents);
      case constantsModule.BONUS_CHECKBOX_CONDITION_VERIF:
        return questionComponents.BonusCheckboxConditionVerif(inputPropsQuestionsComponents);
      case constantsModule.DROPDOWN:
        return questionComponents.Dropdown(inputPropsQuestionsComponents);
      case constantsModule.BONUS_RADIO_CONDITION_VERIF:
        return questionComponents.BonusRadioConditionVerif(inputPropsQuestionsComponents);
      case constantsModule.RADIO_HIDE_COMPONENTS:
        return questionComponents.RadioHideComponents(inputPropsQuestionsComponents);
      case constantsModule.RADIO_CONDITION_VERIF:
        return questionComponents.RadioConditionVerif(inputPropsQuestionsComponents);
      case constantsModule.RADIO_CONDITION_INPUT_VERIF:
        return questionComponents.RadioConditionInputVerif(inputPropsQuestionsComponents);
      case constantsModule.SUBJECT_TITLE:
        return questionComponents.SubjectTitle(inputPropsQuestionsComponents);
      case constantsModule.INDICATIONS:
        return questionComponents.Indications(inputPropsQuestionsComponents);
      case constantsModule.TITLE_GROUP:
        return questionComponents.TitleGroup(inputPropsQuestionsComponents);
      default:
        return <></>;
    }
  };

  return displayQuestion();
};

export default Question;
