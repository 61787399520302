import * as yup from 'yup';

const verificationRequired = yup.string().required('Verification is a required field');
const positiveInteger = yup
  .number()
  .typeError('Positive Integer only')
  .integer('Positive Integer only ')
  .min(0, 'Positive Integer only')
  .required('Mandatory field');
const positiveFloat = yup
  .number()
  .typeError('Positive Float only')
  .min(0, 'Positive Float only')
  .required('Mandatory field');
const minAltitude100Km = yup
  .number()
  .typeError('Positive float only')
  .min(100, 'Min altitude 100 km')
  .max(300000, 'Max altitude 300 000 km')
  .required('Mandatory field');
const angleBetween0And180 = yup
  .number()
  .typeError('Angle between 0 and 180°')
  .min(0, 'Minimum value of 0°')
  .max(180, 'Maximum value of 180°')
  .required('Mandatory field');
const valueBetween0And1 = yup
  .number()
  .typeError('Value between 0 and 1')
  .min(0, 'Minimum value of 0')
  .max(1, 'Value between 0 and 1')
  .required('Mandatory field');

const valueBetween0And100 = yup
  .number()
  .typeError('Value between 0 and 100')
  .min(0, 'Minimum value of 0')
  .max(100, 'Maximum value of 100')
  .required('Mandatory field');

const mandatoryAnswerField = yup.string().required('Mandatory field');
const mandatoryFieldArray = yup
  .array()
  .of(yup.boolean())
  .test({
    name: 'one-true',
    message: 'Mandatory field',
    test(val) {
      return val.filter((value) => value === true).length > 0;
    },
  });

const positiveIntegersWithVerification = yup.object({
  answer: yup.lazy(() => positiveInteger),
  verification: yup.lazy(() => verificationRequired),
});

const positiveFloatWithVerification = yup.object({
  answer: yup.lazy(() => positiveFloat),
  verification: yup.lazy(() => verificationRequired),
});

const minAltitude100kmWithVerification = yup.object({
  answer: yup.lazy(() => minAltitude100Km),
  verification: yup.lazy(() => verificationRequired),
});

const angleBetween0And180WithVerification = yup.object({
  answer: yup.lazy(() => angleBetween0And180),
  verification: yup.lazy(() => verificationRequired),
});

const valueBetween0And1WithVerification = yup.object({
  answer: yup.lazy(() => valueBetween0And1),
  verification: yup.lazy(() => verificationRequired),
});

const valueBetween0And100WithVerification = yup.object({
  answer: yup.lazy(() => valueBetween0And100),
  verification: yup.lazy(() => verificationRequired),
});

const mandatoryFieldWithVerification = yup.object({
  answer: yup.lazy(() => mandatoryAnswerField),
  verification: yup.lazy(() => verificationRequired),
});

const mandatoryAnswerAndInputBetween0And1WithVerification = yup.object({
  answerRadio: yup.lazy(() => mandatoryAnswerField),
  answerInput: yup.lazy(() => valueBetween0And1),
  verification: yup.lazy(() => verificationRequired),
});

const mandatoryFieldArrayWithVerification = yup.object({
  answer: yup.lazy(() => mandatoryFieldArray),
  verification: yup.lazy(() => verificationRequired),
});

const mandatoryFieldAnswer = yup.object({
  answer: yup.lazy(() => mandatoryAnswerField),
});

const validationTypeQuestions = yup.lazy((question) => {
  switch (question.validationID) {
    case 'VALIDATION_POSITIVE_INTEGER_VERIF':
      return positiveIntegersWithVerification;
    case 'VALIDATION_POSITIVE_FLOAT_VERIF':
      return positiveFloatWithVerification;
    case 'VALIDATION_MIN_ALTITUDE_100KM_VERIF':
      return minAltitude100kmWithVerification;
    case 'VALIDATION_ANGLE_BETWEEN_0_AND_180_VERIF':
      return angleBetween0And180WithVerification;
    case 'VALIDATION_VALUE_BETWEEN_0_AND_1_VERIF':
      return valueBetween0And1WithVerification;
    case 'VALIDATION_VALUE_BETWEEN_0_AND_100_VERIF':
      return valueBetween0And100WithVerification;
    case 'VALIDATION_MANDATORY_FIELD_VERIF':
      return mandatoryFieldWithVerification;
    case 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF':
      return mandatoryFieldArrayWithVerification;
    case 'VALIDATION_MANDATORY_FIELD_ANSWER':
      return mandatoryFieldAnswer;
    case 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF':
      return mandatoryAnswerAndInputBetween0And1WithVerification;
    default:
      return yup.object({});
  }
});

const allValidationTypeQuestions = yup.array().of(validationTypeQuestions);

export {
  positiveIntegersWithVerification,
  positiveFloatWithVerification,
  minAltitude100kmWithVerification,
  angleBetween0And180WithVerification,
  valueBetween0And1WithVerification,
  valueBetween0And100WithVerification,
  mandatoryFieldWithVerification,
  mandatoryAnswerAndInputBetween0And1WithVerification,
  mandatoryFieldArrayWithVerification,
  mandatoryFieldAnswer,
  allValidationTypeQuestions,
};
