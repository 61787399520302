import { useEffect, useRef } from 'react';
import * as constantsModule from '../../../../utils/modules/constants';
import {
  angleBetween0And180WithVerification,
  mandatoryAnswerAndInputBetween0And1WithVerification,
  mandatoryFieldAnswer,
  mandatoryFieldArrayWithVerification,
  mandatoryFieldWithVerification,
  minAltitude100kmWithVerification,
  positiveFloatWithVerification,
  positiveIntegersWithVerification,
  valueBetween0And1WithVerification,
  valueBetween0And100WithVerification,
} from '../schema/questionValidation';

const buildInitState = (obj) => {
  const basicState = {
    type: obj.type,
    question: obj.question,
    validationID: obj.validationID,
    explanation: obj.explanation,
    answerWeights: obj.answerWeights,
    userComments: obj.userComments,
    adminComments: obj.adminComments,
  };
  switch (obj.type) {
    case constantsModule.INPUT_VERIF:
      return {
        ...basicState,
        answer: obj.answer,
        choicesVerification: obj.choicesVerification,
        verification: obj.verification,
      };
    case constantsModule.CHECKBOX_CONDITION_VERIF:
      return {
        ...basicState,
        choices: obj.choices,
        answer: obj.answer,
        conditionValuesOnAnswer: obj.conditionValuesOnAnswer,
        choicesVerification: obj.choicesVerification,
        verification: obj.verification,
      };
    case constantsModule.BONUS_CHECKBOX_CONDITION_VERIF:
      return {
        ...basicState,
        choices: obj.choices,
        answer: obj.answer,
        conditionValuesOnAnswer: obj.conditionValuesOnAnswer,
        choicesVerification: obj.choicesVerification,
        verification: obj.verification,
      };
    case constantsModule.DROPDOWN:
      return {
        ...basicState,
        choices: obj.choices,
        answer: obj.answer,
        emptyOption: obj.emptyOption,
      };
    case constantsModule.RADIO_HIDE_COMPONENTS:
      return {
        ...basicState,
        choices: obj.choices,
        answer: obj.answer,
        components: obj.components,
      };
    case constantsModule.RADIO_CONDITION_VERIF:
      return {
        ...basicState,
        choices: obj.choices,
        answer: obj.answer,
        conditionValuesOnAnswer: obj.conditionValuesOnAnswer,
        choicesVerification: obj.choicesVerification,
        verification: obj.verification,
      };
    case constantsModule.BONUS_RADIO_CONDITION_VERIF:
      return {
        ...basicState,
        choices: obj.choices,
        answer: obj.answer,
        conditionValuesOnAnswer: obj.conditionValuesOnAnswer,
        choicesVerification: obj.choicesVerification,
        verification: obj.verification,
      };
    case constantsModule.RADIO_CONDITION_INPUT_VERIF:
      return {
        ...basicState,
        choices: obj.choices,
        answerInput: obj.answerInput,
        answerRadio: obj.answerRadio,
        conditionValuesOnAnswer: obj.conditionValuesOnAnswer,
        choicesVerification: obj.choicesVerification,
        verification: obj.verification,
      };
    case constantsModule.SUBJECT_TITLE:
      return {
        type: obj.type,
        title: obj.title,
      };
    case constantsModule.INDICATIONS:
      return {
        type: obj.type,
        title: obj.title,
      };
    case constantsModule.TITLE_GROUP:
      return {
        type: obj.type,
        title: obj.title,
      };
    default:
      return {
        ...basicState,
      };
  }
};

const handleValidation = async ({ question, setErrors }) => {
  try {
    switch (question.validationID) {
      case 'VALIDATION_POSITIVE_INTEGER_VERIF':
        await positiveIntegersWithVerification.validate(question, { abortEarly: false });
        break;
      case 'VALIDATION_POSITIVE_FLOAT_VERIF':
        await positiveFloatWithVerification.validate(question, { abortEarly: false });
        break;
      case 'VALIDATION_MIN_ALTITUDE_100KM_VERIF':
        await minAltitude100kmWithVerification.validate(question, { abortEarly: false });
        break;
      case 'VALIDATION_ANGLE_BETWEEN_0_AND_180_VERIF':
        await angleBetween0And180WithVerification.validate(question, { abortEarly: false });
        break;
      case 'VALIDATION_VALUE_BETWEEN_0_AND_1_VERIF':
        await valueBetween0And1WithVerification.validate(question, { abortEarly: false });
        break;
      case 'VALIDATION_VALUE_BETWEEN_0_AND_100_VERIF':
        await valueBetween0And100WithVerification.validate(question, { abortEarly: false });
        break;
      case 'VALIDATION_MANDATORY_FIELD_VERIF':
        await mandatoryFieldWithVerification.validate(question, { abortEarly: false });
        break;
      case 'VALIDATION_MANDATORY_FIELD_ARRAY_VERIF':
        await mandatoryFieldArrayWithVerification.validate(question, { abortEarly: false });
        break;
      case 'VALIDATION_MANDATORY_FIELD_ANSWER':
        await mandatoryFieldAnswer.validate(question, { abortEarly: false });
        break;
      case 'VALIDATION_MANDATORY_ANSWER_AND_INPUT_VERIF':
        await mandatoryAnswerAndInputBetween0And1WithVerification.validate(question, {
          abortEarly: false,
        });
        break;
      default:
        break;
    }
    setErrors([]);
    return [];
  } catch (err) {
    setErrors(err.errors);
    return err.errors;
  }
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export { buildInitState, handleValidation, usePrevious };
