import React, { useState, useEffect, useRef } from 'react';
import { Column } from '@ant-design/plots';
import { exportComponentAsPNG } from 'react-component-export-image';
import { SaveAsIcon } from '@heroicons/react/outline';

const ModuleHistogramCumulativeScore = ({ results }) => {
  const [data, setData] = useState([]);
  const histogramRef = useRef();

  const percentStrToPercentNum = (string) => {
    return Number((string * 100).toFixed(2));
  };

  useEffect(() => {
    setData(
      results
        .map(({ name: module, score }, idx) => {
          return [
            {
              type: 'Mission Index',
              score: 0.5 * percentStrToPercentNum(score.missionIndex),
              module,
            },
            {
              type: 'COLA',
              score: 0.165 * percentStrToPercentNum(score.collisionAvoidance),
              module,
            },
            {
              type: 'DIT',
              score: 0.12 * percentStrToPercentNum(score.detectionIdentificationTracking),
              module,
            },
            {
              type: 'Data Sharing',
              score: 0.165 * percentStrToPercentNum(score.dataSharing),
              module,
            },
            {
              type: 'Standards',
              score: 0.05 * percentStrToPercentNum(score.applicationOfDesignAndOperation),
              module,
            },
          ];
        })
        .flat()
    );
  }, [results]);

  const props = {
    data,
    isStack: true,
    limitInPlot: false,
    xField: 'module',
    yField: 'score',
    seriesField: 'type',
    meta: {
      score: {
        alias: 'aa',
        min: 0,
        max: 100,
      },
    },
    annotations: [
      // {
      //   type: 'text',
      //   position: [0.05, 40],
      //   content: 'Bronze ',
      //   offsetX: 175,
      //   style: {
      //     textBaseline: 'bottom',
      //   },
      // },
      {
        type: 'line',
        start: [-1, 40],
        end: [2, 40],
        style: {
          stroke: '#C28342',
          lineDash: [10, 5],
        },
      },
      // {
      //   type: 'text',
      //   position: [0.05, 55],
      //   content: 'Silver ',
      //   offsetX: 175,
      //   style: {
      //     textBaseline: 'bottom',
      //   },
      // },
      {
        type: 'line',
        start: [-1, 55],
        end: [2, 55],
        style: {
          stroke: '#7C7C7C',
          lineDash: [10, 5],
        },
      },
      // {
      //   type: 'text',
      //   position: [0.05, 70],
      //   content: 'Gold ',
      //   offsetX: 175,
      //   style: {
      //     textBaseline: 'bottom',
      //   },
      // },
      {
        type: 'line',
        start: [-1, 70],
        end: [2, 70],
        style: {
          stroke: '#FAD948',
          lineDash: [10, 5],
        },
      },
      // {
      //   type: 'text',
      //   position: [0.05, 80],
      //   content: 'Platinum ',
      //   offsetX: 175,
      //   style: {
      //     textBaseline: 'bottom',
      //   },
      // },
      {
        type: 'line',
        start: [-1, 80],
        end: [2, 80],
        style: {
          stroke: '#C9C9C9',
          lineDash: [10, 5],
        },
      },
    ],
    label: {
      position: 'bottom',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
      style: {
        fontSize: 0,
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'top',
    },
  };

  return (
    <div>
      <div className="flex w-full flex-row-reverse">
        <button
          type="button"
          className="inline-flex items-center p-0.5 border border-transparent rounded-md shadow-sm text-black"
          onClick={() => exportComponentAsPNG(histogramRef)}>
          <SaveAsIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div ref={histogramRef}>
        <Column {...props} />
      </div>
    </div>
  );
};

export default ModuleHistogramCumulativeScore;
