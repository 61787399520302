import React, { useState, useEffect, useRef } from 'react';
import { Radar } from '@ant-design/plots';
import { exportComponentAsPNG } from 'react-component-export-image';
import { SaveAsIcon } from '@heroicons/react/outline';

const ModuleWebChart = ({ results }) => {
  const [data, setData] = useState([]);
  const webChartRef = useRef();

  const percentStrToPercentNum = (string) => {
    return Number((string * 100).toFixed(2));
  };

  useEffect(() => {
    setData(
      results
        .map(({ name: module, score }, idx) => {
          return [
            {
              item: 'Mission Index',
              score: percentStrToPercentNum(score.missionIndex),
              module,
            },
            {
              item: 'COLA',
              score: percentStrToPercentNum(score.collisionAvoidance),
              module,
            },
            {
              item: 'DIT',
              score: percentStrToPercentNum(score.detectionIdentificationTracking),
              module,
            },
            {
              item: 'Data Sharing',
              score: percentStrToPercentNum(score.dataSharing),
              module,
            },
            {
              item: 'Standards',
              score: percentStrToPercentNum(score.applicationOfDesignAndOperation),
              module,
            },
            {
              item: 'External',
              score: percentStrToPercentNum(score.externalServices),
              module,
            },
          ];
        })
        .flat()
    );
  }, [results]);

  const props = {
    data,
    xField: 'item',
    yField: 'score',
    seriesField: 'module',
    meta: {
      score: {
        alias: 'aa',
        min: 0,
        max: 100,
      },
    },
    xAxis: {
      line: null,
      tickLine: null,
      grid: {
        line: {
          style: {
            lineDash: null,
          },
        },
      },
    },
    point: {
      size: 2,
    },
  };

  return (
    <div>
      <div className="flex w-full flex-row-reverse">
        <button
          type="button"
          className="inline-flex items-center p-0.5 border border-transparent rounded-md shadow-sm text-black"
          onClick={() => exportComponentAsPNG(webChartRef)}>
          <SaveAsIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div ref={webChartRef}>
        <Radar {...props} />
      </div>
    </div>
  );
};
export default ModuleWebChart;
