export const tabsKeys = [
  'missionIndex',
  'collisionAvoidanceCapabilities',
  'dataSharing',
  'detectionIdentificationTracking',
  'applicationOfDesignAndOperation',
  'externalServices',
];

export const tabsTitles = [
  'Mission Index',
  'Collision Avoidance Capabilities',
  'Data Sharing',
  'Detection Identification Tracking',
  'Application of Design and Operation Standards',
  'External Services',
];
