import { useRef, useState, useEffect } from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';
import * as endpoints from '../../utils/endpoints';
import MessageModal from './components/MessageModal';
import SpinnerIcon from '../components/SpinnerIcon';

const AdminRegisterAccount = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [message, setMessage] = useState('');
  const accessToken = localStorage.getItem('accessToken');

  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const registerRequest = {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        fullName,
      }),
    };

    try {
      setIsFetching(true);
      const response = await fetch(endpoints.API + endpoints.REGISTER, registerRequest);
      if (!response.ok) {
        const js = await response.json();
        console.log(js);
      } else {
        const res = await response.json();

        setIsOpen(true);
        setMessage(
          `Account successfully registered, email: ${res.email}, password: ${res.password}`
        );

        setEmail('');
        setFullName('');
        setPassword('');
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    }
    setIsFetching(false);
  };

  const generatePassword = () => {
    setPassword(Math.random().toString(36).slice(-10));
  };

  return (
    <>
      <MessageModal message={message} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="min-h-full hidden sm:flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <p ref={errRef} className={errMsg ? '' : 'hidden'}>
            {errMsg}
          </p>
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Register an account
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  id="email-address"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="email"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Enter Email address"
                />
              </div>
              <div className="mt-2">
                <label htmlFor="full-name" className="sr-only">
                  Full Name
                </label>
                <input
                  type="text"
                  id="full-name"
                  autoComplete="off"
                  onChange={(e) => setFullName(e.target.value)}
                  value={fullName}
                  name="fullName"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Enter Full Name"
                />
              </div>
              <div>
                <button
                  type="button"
                  onClick={generatePassword}
                  className="inline-flex my-2 items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600">
                  Generate Password
                </button>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  type="text"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  name="password"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isFetching}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {isFetching ? (
                    <SpinnerIcon />
                  ) : (
                    <LockClosedIcon
                      className="h-5 w-5 text-gray-500 group-hover:text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </span>
                Register Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminRegisterAccount;
