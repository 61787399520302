import * as constants from '../../../utils/constants';

import FormTable from '../../dashboard/components/FormTable';

export default function AdminUserFormsTable({
  userId,
  submittedForms,
  loadingSubmittedForms,
  ratedForms,
  loadingRatedForms,
}) {
  return (
    <div className="min-h-full">
      <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] flex flex-col">
        <main className="flex-1">
          {/* Forms table  */}
          {!loadingSubmittedForms && (
            <FormTable data={submittedForms} userId={userId} type={constants.FORM_TYPE.Submitted} />
          )}
          {!loadingRatedForms && (
            <FormTable data={ratedForms} userId={userId} type={constants.FORM_TYPE.Rated} />
          )}
        </main>
      </div>
    </div>
  );
}
