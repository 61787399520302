import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Layout from './components/Layout';
import NotFound from './components/NotFound';
import Unauthorized from './components/Unauthorized';

import buildProtectedRoutes from './utils/routing/buildProtectedRoutes';
import * as constantsRoute from './utils/routing/constants';

import Login from './components/unauthenticated/login/Login';

const App = () => {
  return (
    <Routes>
      <Route path={constantsRoute.ROUTE_DEFAULT} element={<Layout />}>
        {/* public routes */}
        <Route path={constantsRoute.ROUTE_LOGIN} element={<Login />} />
        <Route path={constantsRoute.ROUTE_UNAUTHORIZED} element={<Unauthorized />} />

        {/* protected routes */}
        {buildProtectedRoutes()}

        {/* catch all */}
        <Route path={constantsRoute.ROUTE_NOT_FOUND} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
