import AdminMissionForm from '../../components/admin/AdminMissionForm';
import AdminRegisterAccount from '../../components/admin/AdminRegisterAccount';

import UserForms from '../../components/admin/UserForms';
import UserMissionForm from '../../components/user/UserMissionForm';
import SavedForm from '../../components/user/SavedForm';
import UserForm from '../../components/user/UserForm';
import Dashboard from '../../components/dashboard/Dashboard';
import Settings from '../../components/settings/Settings';
import RatedForm from '../../components/user/RatedForm';
import CompareFormResults from '../../components/admin/components/CompareFormResults';

export const ROUTE_HOME = '/';
export const ROUTE_DEFAULT = '/';
export const ROUTE_ADMIN_DASHBOARD = '/';
export const ROUTE_USER_DASHBOARD = '/';
export const ROUTE_CREATE_MISSION = '/new';
export const ROUTE_MISSION_FORM_DEBUG = '/debug';
export const ROUTE_USER_FORMS = (userId) => `/users/${userId}`;
export const ROUTE_USER_FORM = (userId, formId) => `/users/${userId}/forms/${formId}`;
export const ROUTE_USER_SAVED_FORM = (userId, formId) => `/users/${userId}/forms/save/${formId}`;
export const ROUTE_USER_RATED_FORM = (userId, formId) => `/users/${userId}/forms/rate/${formId}`;
export const ROUTE_COMPARE_RESULTS_FORM = '/admin/compare';
export const ROUTE_LOGIN = '/login';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_ADMIN_REGISTER = '/admin/register';
export const ROUTE_UNAUTHORIZED = '/unauthorized';
export const ROUTE_NOT_FOUND = '*';

export const ROLES = {
  User: 'user',
  Admin: 'admin',
};

export const protectedRoutes = [
  {
    allowedRoles: [ROLES.Admin],
    components: [
      { Element: UserForms, path: ROUTE_USER_FORMS(':userId') },
      { Element: AdminMissionForm, path: ROUTE_MISSION_FORM_DEBUG },
      { Element: AdminRegisterAccount, path: ROUTE_ADMIN_REGISTER },
      { Element: CompareFormResults, path: ROUTE_COMPARE_RESULTS_FORM },
    ],
  },
  {
    allowedRoles: [ROLES.Admin, ROLES.User],
    components: [
      { Element: Dashboard, path: ROUTE_HOME },
      { Element: Settings, path: ROUTE_SETTINGS },
      { Element: UserForm, path: ROUTE_USER_FORM(':userId', ':formId') },
      { Element: RatedForm, path: ROUTE_USER_RATED_FORM(':userId', ':formId') },
    ],
  },
  {
    allowedRoles: [ROLES.User],
    components: [
      { Element: UserMissionForm, path: ROUTE_CREATE_MISSION },
      { Element: SavedForm, path: ROUTE_USER_SAVED_FORM(':userId', ':formId') },
    ],
  },
];

export const navBarRoutes = new Map([
  [
    ROLES.Admin,
    [
      { path: ROUTE_ADMIN_DASHBOARD, label: 'Dashboard' },
      { path: ROUTE_MISSION_FORM_DEBUG, label: 'Debug' },
      { path: ROUTE_ADMIN_REGISTER, label: 'Register Account' },
    ],
  ],
  [ROLES.User, [{ path: ROUTE_USER_DASHBOARD, label: 'Dashboard' }]],
]);
