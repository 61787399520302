import parse from 'html-react-parser';

const Indications = ({ question }) => {
  return (
    <>
      <div className="py-2">{parse(question.title)}</div>
    </>
  );
};

export default Indications;
