import React from 'react';
import Question from '../question/Question';
import { buildInitState } from '../question/utils/utils';

const displayModule = ({ module, setChangesModule, displayType }) => {
  return React.Children.toArray(
    module.map((item, index) => {
      return (
        <div className="pb-2">
          <Question
            displayType={displayType}
            question={buildInitState(item)}
            handleChanges={(updatedValue) => {
              const updatedAnswer = [...module];
              updatedAnswer[index] = updatedValue;
              setChangesModule(updatedAnswer);
            }}
          />
        </div>
      );
    })
  );
};

export default displayModule;
