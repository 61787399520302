import parse from 'html-react-parser';

const TitleGroup = ({ question }) => {
  return (
    <>
      <div className="pt-2 font-bold text-lg">{parse(question.title)}</div>
    </>
  );
};

export default TitleGroup;
