export const FORM_TYPE = {
  Saved: 'Saved',
  Submitted: 'Submitted',
  Rated: 'Rated',
  ChangesRequested: 'ChangesRequested',
};

export const FORM_TYPE_TEXT = {
  Saved: 'Ongoing forms',
  Submitted: 'Submitted forms',
  Rated: 'Rated forms',
};

export const formatDate = (date) => {
  return new Date(Number(date)).toLocaleString('en-US', {
    timeZone: 'UTC',
    timeZoneName: 'short',
  });
};
