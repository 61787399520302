import { Fragment } from 'react';
import { UserCircleIcon } from '@heroicons/react/outline';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';

import useAuth from '../../../hooks/useAuth';
import { ROUTE_LOGIN, ROUTE_SETTINGS } from '../../../utils/routing/constants';
import handleLogout from '../../../utils/routing/handleLogout';

const Profile = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const location = useLocation();

  const buttonsSign = () => {
    switch (location.pathname) {
      case '/register':
        return (
          <button className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-600 hover:bg-gray-700">
            <NavLink to={ROUTE_LOGIN}>Sign in</NavLink>
          </button>
        );
      default:
        return <></>;
    }
  };

  return auth?.email ? (
    <Menu as="div">
      <div>
        <Menu.Button className="flex ml-4 text-sm rounded-full text-gray-400 hover:text-white">
          <span className="sr-only">Profile</span>
          <UserCircleIcon className="h-10 w-auto text-neutral-600" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            <div className={'block bg-gray-50 px-4 py-2 text-sm text-gray-700'}>{auth.email}</div>
          </Menu.Item>
          <Menu.Item>
            <NavLink to={ROUTE_SETTINGS}>
              <div className={'cursor-pointer block px-4 py-2 text-sm text-gray-700'}>Settings</div>
            </NavLink>
          </Menu.Item>
          <Menu.Item onClick={() => handleLogout(setAuth, navigate)}>
            <div className={'cursor-pointer block px-4 py-2 text-sm text-gray-700'}>Logout</div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  ) : (
    buttonsSign()
  );
};

export default Profile;
