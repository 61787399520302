import { useEffect, useState } from 'react';

import { allValidationTypeQuestions } from './questionValidation';
import * as constantsModule from '../../../../utils/modules/constants';

const useValidateMissionForm = ({ missionForm }) => {
  const [errors, setErrors] = useState(['']);

  useEffect(() => {
    const allQuestions = [
      ...missionForm?.missionIndex,
      ...missionForm?.collisionAvoidance,
      ...missionForm?.dataSharing,
      ...missionForm?.detectionIdentificationTracking,
      ...missionForm?.applicationOfDesignAndOperation,
      ...missionForm?.externalServices,
    ].filter((question) => !constantsModule.OTHER_TYPES.includes(question.type));

    const getAllErrors = async () => {
      try {
        await allValidationTypeQuestions.validate(allQuestions, { abortEarly: false });
        setErrors([]);
      } catch (err) {
        setErrors(err.errors);
      }
    };

    getAllErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionForm]);

  return errors;
};

export default useValidateMissionForm;
