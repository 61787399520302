import useAuth from '../../hooks/useAuth';
import LeftSide from './components/LeftSide';
import RightSide from './components/RightSide';

const Navbar = () => {
  const { auth } = useAuth();

  return (
    <nav className="w-full border-b">
      <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <LeftSide auth={auth} />
          <RightSide />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
