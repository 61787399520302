import { XIcon } from '@heroicons/react/outline';

const FloatingBanner = ({
  show,
  setShow,
  icon,
  text,
  primaryColor,
  secondaryColor,
  hoverColor,
}) => {
  return (
    show && (
      <div className="fixed z-40 bottom-0 inset-x-0 pb-2 sm:pb-5">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className={`p-2 rounded-lg ${primaryColor} shadow-lg sm:p-3`}>
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className={`flex p-2 rounded-lg ${secondaryColor}`}>{icon}</span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">{text}</span>
                  <span className="hidden md:inline">{text}</span>
                </p>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  onClick={() => setShow(false)}
                  className={`-mr-1 flex p-2 rounded-md hover:${hoverColor} focus:outline-none focus:ring-2 focus:ring-white`}>
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default FloatingBanner;
