import { useEffect, useState } from 'react';
import * as endpoints from '../utils/endpoints';

// Custom hook to fetch data from an endpoint
const useFetchCall = (endpoint, request) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(endpoints.API + endpoint, request);
        if (!response.ok) {
          const js = await response.json();
          throw new Error(JSON.stringify(js));
        } else {
          let dataResponse = await response.json();
          setData(dataResponse);
        }
      } catch (e) {
        setError(JSON.parse(e.message));
      }
      setLoading(false);
    };

    fetchData();

    return () => {
      setData(null);
      setError(null);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint]);

  return [data, loading, error];
};

export default useFetchCall;
