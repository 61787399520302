import parse from 'html-react-parser';

const SubjectTitle = ({ question }) => {
  return (
    <>
      <div className="py-2 font-bold text-2xl">{parse(question.title)}</div>
    </>
  );
};

export default SubjectTitle;
