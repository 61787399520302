import React from 'react';
import { Route } from 'react-router-dom';

import RequireAuth from '../../components/RequireAuth';
import { protectedRoutes } from './constants';

const buildProtectedRoutes = () => {
  return React.Children.toArray(
    protectedRoutes.map(({ allowedRoles, components }, prIdx) => (
      <Route element={<RequireAuth allowedRoles={allowedRoles} />}>
        {React.Children.toArray(
          components.map(({ Element, path }, cIdx) => <Route path={path} element={<Element />} />)
        )}
      </Route>
    ))
  );
};

export default buildProtectedRoutes;
