import { useEffect, useState } from 'react';

import useFetchCall from '../../api/useFetchCall';
import * as endpoints from '../../utils/endpoints';
import * as constantsRoute from '../../utils/routing/constants';
import Table from './components/Table';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import { TableIcon } from '@heroicons/react/outline';

const AdminDashboard = () => {
  const accessToken = localStorage.getItem('accessToken');

  const [usersList, setUsersList] = useState([]);

  const getUsersRequest = {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  };

  const [data, loading] = useFetchCall(endpoints.USERS, getUsersRequest);

  useEffect(() => {
    if (data?.data) {
      setUsersList(data.data);
    }
  }, [data]);

  const DisplayUsers = ({ users }) => {
    if (users.length === 0) {
      return <>No users</>;
    }
    return <Table users={users} />;
  };

  return !loading ? (
    <>
      <div className="min-h-full">
        <div className="lg:pl-[calc(12vw)] lg:pr-[calc(12vw)] pb-8 flex flex-col">
          <section>
            <div className="pt-8 pb-6  text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Admin Dashboard Page
              <Link
                to={constantsRoute.ROUTE_COMPARE_RESULTS_FORM}
                className="text-indigo-600 hover:text-indigo-900">
                <div
                  type="button"
                  className="inline-flex ml-2 items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600">
                  <TableIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                  Compare Forms
                </div>
              </Link>
            </div>
            <DisplayUsers users={usersList} />
          </section>
        </div>
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default AdminDashboard;
