import { useEffect, useState } from 'react';

import useAuth from '../../hooks/useAuth';
import useFetchCall from '../../api/useFetchCall';

import * as endpoints from './../../utils/endpoints';

import UserFormsTable from '../dashboard/UserFormsTable';
import WelcomeModal from './components/WelcomeModal';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

const UserDashboard = () => {
  const { auth } = useAuth();
  const accessToken = localStorage.getItem('accessToken');
  const welcomeModal = localStorage.getItem('welcomeModal');

  const [isWelcomeOpen, setIsWelcomeOpen] = useState(welcomeModal === null ? true : false);
  const [isFetching, setIsFetching] = useState(true);

  const [submittedForms, setSubmittedForms] = useState([]);
  const [savedForms, setSavedForms] = useState([]);
  const [ratedForms, setRatedForms] = useState([]);

  const getUsersRequest = {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  };

  const [dataSavedForms, loadingSavedForms] = useFetchCall(
    endpoints.USER_SAVED_FORMS,
    getUsersRequest
  );
  const [dataSubmittedForms, loadingSubmittedForms] = useFetchCall(
    endpoints.USER_FORMS(auth.id),
    getUsersRequest
  );
  const [dataRatedForms, loadingRatedForms] = useFetchCall(
    endpoints.USER_RATED_FORMS(auth.id),
    getUsersRequest
  );

  useEffect(() => {
    if (dataSubmittedForms?.data) {
      setSubmittedForms(dataSubmittedForms.data);
    }
  }, [dataSubmittedForms]);

  useEffect(() => {
    if (dataSavedForms?.data) {
      setSavedForms(dataSavedForms.data);
    }
  }, [dataSavedForms]);

  useEffect(() => {
    if (dataRatedForms?.data) {
      setRatedForms(dataRatedForms.data);
    }
  }, [dataRatedForms]);

  useEffect(() => {
    if (!loadingSavedForms && !loadingSubmittedForms && !loadingRatedForms) {
      setIsFetching(false);
    }
  }, [loadingSavedForms, loadingSubmittedForms, loadingRatedForms]);

  return (
    <>
      <WelcomeModal open={isWelcomeOpen} setOpen={setIsWelcomeOpen} />
      <section>
        <div className="lg:pl-[calc(14vw)] lg:pr-[calc(14vw)] pl-4 pt-8 ">
          <div className="font-bold text-2xl  leading-7 text-gray-900 sm:text-3xl sm:truncate">
            User Dashboard Page
            <button
              type="button"
              className="items-center pl-2 rounded-full text-gray"
              onClick={() => setIsWelcomeOpen(true)}>
              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </section>
      {!isFetching && (
        <UserFormsTable
          savedForms={savedForms}
          loadingSavedForms={loadingSavedForms}
          submittedForms={submittedForms}
          loadingSubmittedForms={loadingSubmittedForms}
          ratedForms={ratedForms}
          loadingRatedForms={loadingRatedForms}
        />
      )}
    </>
  );
};

export default UserDashboard;
